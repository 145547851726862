.product-item-mobile{
    min-width: 100%;
    padding-bottom: 0.75rem;
    gap: 0.125rem;
    flex-direction: column;
    display: flex;
    cursor: pointer;
    background: rgb(255, 255, 255);
    border: 0.5px solid rgb(232, 232, 232);
    box-shadow: rgba(0, 0, 0, 0.04) 2px 2px 8px;
    border-radius: 8px;
    max-width: 100%;
    /* overflow-y:scroll; */
}
.product-img-mobile{
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    display:flex;
    justify-content:center;
}
.product-img-mobile img{
    height:140px;
    width:140px;
}
.sub-mobile-name{
    overflow: hidden;
    color: rgb(31, 31, 31);
    font-weight: 600;
    font-size: 13px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 18px;
    width: 100%;
    height: 36px;
    margin-bottom: 6px;
}
.product-actions-mobile{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.p-btn-mobile{
    cursor: pointer;
    width: 66px;
    border: 1px solid rgb(49, 134, 22);
    height: 31px;
    color: rgb(49, 134, 22);
    font-weight: 600;
    font-size: 13px;
    background-color: rgb(247, 255, 249);
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
}