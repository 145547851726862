.flri {
    text-align: right;
}

.display-bio h6 {
    border-bottom: 3px solid #6A0DAD;
    width: fit-content;
    padding: 6px 0px;
}

.display-bio .form-group label {
    text-transform: capitalize;
}

.display-bio input,
.display-bio select,
.display-bio .form-control {
    font-weight: 600;
}

.project-info {
    display: flex;
}

.project-titles {
    flex: 1;
}

@media screen and (max-width: 768.98px) {
    .col-margin {
        margin-bottom: 60px;
    }

    .display-projects .container .col-md-6 iframe {
        height: 100%;
    }
}