.navbar-brand img {
    max-width: 130px;
}

a.navbar-brand {
    padding: 0;
}

.navbar-bottom {
    margin-top: 102px;

}

.navbar-container .navbar {
    background: #fff;
    box-shadow: inset 0px -8px 5px -12px #111;
}

.cart-container {
    display: flex;
    width: 130px;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    align-items: center;
    background: #3cac47;
}

.cart-icon-holder {
    font-size: 26px;
    margin-right: 8px;
    flex: 1;
}

.cart-qty p {
    margin-bottom: 0px;
    font-size: 16px;
}

.cart-qty {
    flex: 3;
}

.cart-container:hover {
    background-color: #fff;
    color: #3cac47;
    border: 1px solid #3cac47;
}

.show-tablet {
    display: none;
}

.popup-cart .modal-content {
    border-radius: 0;
    height: 100vh;
}

.popup-cart.modal.show .modal-dialog {
    position: fixed;
    right: 0;
    top: 0;
    margin: 0;
    min-width: 300px;
    max-width: 400px;
}

.modal-title.h4 {
    font-family: 'poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.02em;
    color: #212529;
}

.btn-proceed {
    width: 100%;
    background: #3cac47;
    display: flex;
    padding: 20px 10px;
}

.btn-proceed p {
    margin-bottom: 0;
    font-size: 16px;
}

.btn-proceed:hover,
.btn-proceed:active {
    background: #fff;
    color: #3cac47;
    border: 1px solid #3cac47;
}

p.p-qty {
    flex: 1;
    display: flex;
}

p.p-pay {
    flex: 2;
}

p.p-delivery-time {
    font-weight: 700;
    margin-bottom: 0;
}

.delivery-location {
    flex: 1;
}

svg.home-add {
    font-size: 40px;
}

.delivery-location p {
    margin-bottom: 0px;
}

.del-address {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
}

p.add-type {
    font-weight: 700;
}

img.cart-display-img {
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    max-width: 100%;
}

img.cart-display-imgs {
    border: 1px solid #f2f2f2;
    border-radius: 50%;
    width: 60px;
    height: 60px;
}

.popup-cart .cart-modal-rn {
    height: calc(100vh - 200px);
    overflow-y: scroll;
    background-color: rgb(245, 247, 253);
}

.popup-cart .cart-modal-address {
    background-color: rgb(245, 247, 253);
    padding: 0;
}

.cart-modal-addresses {
    height: calc(100vh - 70px);
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    -ms-overflow-style: none;
}

p.cart-item-price {
    font-weight: 700;
}

.productItem p {
    margin-bottom: 10px;
}

.productItem {
    margin-bottom: 20px;
}

p.cart-total-price {
    font-weight: 700;
}

.search-form button {
    background: no-repeat;
}

.search-form input {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.search-form button {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
}

a.dropdown-item p {
    margin-bottom: 2px;
}

.cartProducts {
    max-height: 340px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.menu-item a.active {
    color: #6A0DAD;
}

.menu-item a.active .sidebar-title {
    color: #6A0DAD;
    font-weight: 600;
}

.mobile-icons-tab a {
    text-decoration: none;
    color: #212529;
}

.mobile-icons-tab a.active {
    color: #6A0DAD;
}

a.menu-item.active {
    color: red;
}

/* .options-container-mobile a.active {
    color: #6A0DAD;
} */

@media screen and (max-width: 991.98px) {
    .show-tablet {
        display: block;
    }

    .show-desktop {
        display: none;
    }

    .cart-container {
        position: fixed;
        z-index: 9;
        bottom: 10px;
        width: calc(100% - 20px);
        display: flex;
        left: 10px;
        right: 10px;
    }
}

@media screen and (max-width: 767.98px) {
    .main-menu {
        display: none;
    }

    /* .right-area {
        display: none;
    } */

    .popup-cart.modal.show .modal-dialog {
        width: 100vw;
    }
}

.cart-modal-rn {
    overflow-y: scroll;
    background-color: rgb(245, 247, 253);
}

.cart-delviry-rn {
    background-color: rgb(255, 255, 255);
}

.cart-modal-rn-address {
    height: calc(100vh - 150px);
    background-color: rgb(245, 247, 253);
}

.nav-locat-icon {
    text-align: center;
    font-size: 30px;
    color: rgb(102, 102, 102);
}

.form-nav-input {
    width: 100%;
    margin-right: 10px;
}

.form-controll {
    border: 1px solid black;
    border-radius: 5px;
    padding: 12px;
    margin: 15px;
}

.form-controll>input,
.form-control>input {
    width: 90%;
    border: none;
    margin-left: 30px
}

.form-controll>input:focus,
.form-control>input:focus {
    border: none;
    outline: none;
}

.search-icon {
    position: absolute;
    width: 30px;
    height: 30px;
    color: black;
}

.location-nav-main {
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 86px;
    display: flex;
    font-size: 16px;
    flex-direction: column;
    cursor: pointer;
}

.location-title {
    color: rgb(0, 0, 0);
    font-weight: 800;
    margin-bottom: 6px;
    font-size: 18px;
}

.location-name {
    font-size: 13px;
    color: rgb(0, 0, 0);
    font-weight: 400;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    width: auto;
    max-width: 210px;
    text-overflow: ellipsis;
}

.empty-img {
    max-width: 100%;
    width: 144px;
    height: 144px;
    overflow: hidden;
}

.Empty-cart {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    background-color: white;
    padding: 16px
}

.navBar-mobile-main {
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 1000;
    background: #fff;
    box-shadow: inset 0px -8px 5px -12px #111;
    padding: 10px;
}

.nav-mobile-header {
    height: auto;
}

.nav-mobile-location {
    align-items: center;
    display: flex;
    width: auto;
    justify-content: space-between;
}

.nav-mobile-time {
    color: rgb(0, 0, 0);
    font-weight: 800;
    margin-bottom: 6px;
    font-size: 18px;
}

.nav-mobile-store {
    font-size: 13px;
    color: rgb(0, 0, 0);
    font-weight: 400;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    width: auto;
    max-width: 156px;
    text-overflow: ellipsis;
}

.account-mobile {
    width: 24px;
    height: 24px;
}

.search-mobile-main {
    padding: 0px 16px 12px;
}

.account-mobile-nav {
    background-color: #fff;
}

.account-number {
    width: 100%;
    background-color: rgb(255, 255, 255);
}

.user-account-number {
    padding: 16px
}

.user-account-number-text {
    font-size: 16px;
    font-family: Okra;
    color: rgb(102, 102, 102);
}

.account-details {
    width: 100%;
    background-color: rgb(255, 255, 255);
    margin-top: 10%;
}

.account-padding {
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
}

.account-info {
    font-size: 12px;
    margin-bottom: 8px;
    color: rgb(153, 153, 153);
    display: block;
}

.order-histoy {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
}

.order-icon {
    color: rgb(102, 102, 102);
    background-color: rgb(247, 247, 247);
    margin-right: 16px;
    border-radius: 12px;
    font-size: 24px;
}

.icon-name {
    font-size: 16px;
    color: black;
    display: inline-block;
}

.loginfooter {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
    background-color: var(--bs-modal-footer-bg);
    border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
    border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.nav-mobile-location1 {
    align-items: center;
    display: flex;
    width: auto;
    gap: 10px
}

.leftarroworder {
    font-size: 24px;
    color: rgb(0, 0, 0);
    font-family: CustomFont;
    margin-left: 16px;
}


img.logo {
    width: 100px;
}

.main-container {
    display: flex;
    justify-content: flex-start;
}

.main-menu {
    /* flex: 1; */
    width: 220px;
    margin-top: 75px;
}

.secondary-menu {
    flex: 2;
    border-right: 1px solid #ddd;
    margin-right: -1px;
}

.content-area {
    /* border-right: 1px solid #ddd; */
    margin-top: 75px;
}

.shortlist_contentarea {
    margin-top: 75px !important;
}

.content-areahome {
    /* min-width: 521px;
    margin-left: 122px !important;
    padding-right: 11px;
    max-width: 542px; */
    width: 100% !important;
    min-width: 532px;
    max-width: 532px;
    margin: 75px auto 0;
    transform: translate(-18%, 0%);
}

.auditions_details_container {
    width: 100% !important;
    margin-top: 76px !important;
}

p.sidebar-name {
    margin: 0;
}

p.sidebar-title {
    margin: 0;
    font-size: 15px;
    line-height: 22.5px;
    text-align: left;
}

.account-topnav a {
    text-decoration: auto;
    color: #000;
}

.right-area {
    margin-top: 82px;
    /* width: 328px; */
    position: sticky;
    top: 0;
    margin-left: 28px;
}

.suggestions-area {
    position: sticky;
    top: 82px;
}

.menu-item {
    text-align: center;
    padding: 20px 30px;
    cursor: pointer;
}

.menu-item a {
    color: #000;
    text-decoration: auto;
}

.menu-item:hover {
    background: #caf0dc;
}

.sidebar-icon {
    font-size: 30px;
}

.menu-icon {
    font-size: 30px;
    width: 32px;
    height: 32px;
}

.secondary-menu-container {
    margin-top: 20px;
    text-align: center;
}

.menu-container {
    position: sticky;
    overflow-y: scroll;
    height: calc(100vh - 75px);
    top: 75px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    border-right: 1px solid #ddd;
    /* padding: 40px 35px; */
    width: 220px;
    box-shadow: 0px 4px 11px 0px #00000036;
}

.sticky-menu {
    margin-top: 75px !important;
}

.non-sticky-menu {
    margin-top: 110px !important;
}

.filters-container .container-form {
    top: 80px;
    position: sticky;
    max-height: calc(100vh - 80px);
    overflow-y: scroll;
}

.audition-detail-page .content-area,
.payments-screen .content-area,
.search-page .content-area {
    flex: 9;
}

img.profile-pic-top {
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

img.profile-pic-mobile-img {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: 1px solid #ED1B24
}

a.switchtag {
    float: right;
    margin-top: 20px;
}

.switchtag {
    text-align: center;
    margin-top: 10px;
    color: red;
    font-size: 15px;
    font-weight: 600;
    line-height: 22.5px;
    text-align: center;
}

.switchtag span {
    color: #212529;
    font-size: 13px;
    font-weight: 400;
}

.form-group.has-icon input.form-control {
    border-radius: 25px;
    height: 45px;
}

svg.top-new-post {
    font-size: 24px;
    color: #212529;
    margin-right: 10px;
    margin-top: 8px;
}

.options-container-mobile a {
    color: rgb(33, 37, 41);
}

.search-screen-container .main-menu {
    flex: 1;
}

.main-container.profile-page .main-menu {
    flex: 1;
}

.main-container.profile-page .content-area {
    flex: 9;
}

.menu-item a {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
}

/* svg.menu-icon {
    margin-right: 20px;
} */

@media screen and (max-width: 991.98px) {


    .main-menu {
        width: 220px;
    }

    .right-area {
        display: none;
    }

    .content-areahome {
        margin-left: 0 !important;
        width: 100% !important;
        max-width: none !important;
        margin-top: 80px;
        padding: 0 10px;
        min-width: auto;
        transform: none;
    }

}

@media screen and (max-width: 767.98px) {

    /* .hide-for-mobile {
        display: none;
    } */
    .content-areahome {
        margin-left: 0 !important;
        width: 100% !important;
        max-width: none !important;
        margin-top: 72px !important;
        padding: 0 10px;
        min-width: auto;

    }

    .right-area {
        display: none;
    }

    .logo {
        width: 100%;
        padding: 7px 28px 7px 3px;
    }

    .content-areahome.non-sticky-menu {
        margin-top: 150px !important;
    }
}