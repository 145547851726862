/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Akaya+Kanadaka&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:wght@300;400;500;600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  /* font-family: 'Roboto', sans-serif; */
  font-family: "Poppins", sans-serif;

}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.form-group {
  margin-bottom: 16px;
}

.form-group label {
  /* font-family: 'Roboto', sans-serif; */
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 8px;
  margin-left: 14px;
  color: #212529;
}

p {
  font-weight: 400;
  font-size: 12.5px;
  line-height: 20px;
  margin-bottom: 0;
}

.form-group.has-icon {
  position: relative;
}

.form-control.form-textarea {
  border: 1px solid #F4D8FF;
  border-radius: 10px;
}

img.inputicon {
  position: absolute;
  right: 20px;
  bottom: 15px;
}

img.inputicon1 {
  position: absolute;
  right: 20px;
  bottom: 15px;
  width: 20px;
  height: 15px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 0px auto 20px;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}

.successmsg,
.errormsg {
  text-align: center;
}



@keyframes stroke {
  100% {
    stroke-dashoffset: 0
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none
  }

  50% {
    transform: scale3d(1.1, 1.1, 1)
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142
  }
}


.crossmark__circle.animateElement {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #ff0000;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.crossmark.animateElement {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #ff0000;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #ff0000;
  animation: fillred .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.crossmark__check.animateElement {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.cross__path.animateElement {
  stroke: #ffffff;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  transform-origin: 50% 50% 0;
}

.cross__path--right.animateElement {
  animation: 0.3s ease 0.8s normal forwards 1 running stroke;
}

.cross__path--left.animateElement {
  animation: 1s ease 0.8s normal forwards 1 running stroke;
}

.loading-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fillred {
  100% {
    box-shadow: inset 0px 0px 0px 30px #ff0000;
  }

}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

.btn-block {
  width: 100%;
}

span.required-star {
  color: #FF0000;
}

.invalid {
  color: #FF0000;
}

.invalid-error {
  border: 1px solid #FF0000;
  border-radius: 4px;
}

.input-box {
  display: flex;
}

.input-box>div {
  flex: 1;
}

@media only screen and (max-width: 991.98px) {}

@media screen and (max-width: 767.98px) {}