.banner-img img {
    max-width: calc(100% - 30px);
    border-radius: 10px;
}
.banners-container {
    padding: 30px 0px;
}
/* .adpost-img img {
    max-width: 100%;
} */

.adpost-item p {
    margin-bottom: 0px;
    padding: 2px 5px;
}

@media screen and (max-width: 767.98px) {
    .banner-img img {
        max-width: 100%;
        padding: 20px;
    }
}
.Audi-upcoming {
    font-size: 17px;
    font-weight: 600;
    color: #212529;
    margin-bottom: 10px;
    font-family: "poppins";
}