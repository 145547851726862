.footer-container {
    /* background-color: #292929; */
    color: white;
    position: relative;
    z-index: 0;
}

.footer-content h4 {
    color: #ffffff;
    margin-bottom: 20px;
}

.footer-content p,
.footer-content ul li a {
    color: #fff;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
}

.footer-bottom {
    background: #6A0DAD;
    padding: 0 20px;
    color: #fff;
    font-weight: bold;
}

.footer-links ul {
    padding: 0;
}

.footer-links ul li {
    list-style: none;
    margin-bottom: 10px;
}

.footer-links ul li a {
    color: #ffffff;
    text-decoration: none;
}

.footer-links ul li a:hover {
    text-decoration: underline;
}

.footer-links {
    background: url(./images/footer.webp);
    padding: 35px 30px 50px 30px;
    background-size: 100%;
}

.text-powered a {
    color: #fff;
    text-decoration: none;
}

.logo-footer img {
    width: 300px;
}

.arrow {
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-right: 8px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.description-footer {
    padding-top: 40px;
    text-align: left;
    margin-bottom: 0;
}

.footer-information {
    margin-top: 40px;
    margin-left: 70px;
}

.foot-our {
    margin-bottom: 40px;
    /* border-bottom: linear-gradient(90deg, #EF443B 0%, rgba(0, 0, 0, 0) 100%); */
    border-bottom: 1px solid;
    padding-bottom: 15px;
    border-image: linear-gradient(90deg, #EF443B 0%, rgba(0, 0, 0, 0) 25%);
    border-image-slice: 1;
}

.footer-copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
}

.address-footer {
    margin-bottom: 20px;
    display: flex;
    gap: 15px;
}
@media screen and (max-width: 767.98px) {
 
    .footer-information {
        margin-left: 0 !important;
    }
    .footer-copyright{
        padding: 12px 5px;
    }
    .footer-container{
        z-index: 0;
    }
    .logo-footer img{
        width: 250px;
    }
    .footer-links{
        padding: 35px 30px 50px 5px;
    }
    
}
@media (min-width: 768px) and (max-width: 992px){
    .footer-container{
        z-index: 0;
    }
}