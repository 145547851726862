.chat-list {
    min-width: 385px;
    max-width: 385px;
    top: 83px;
    left: 100px;
    gap: 0px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 0px #00000040;
    padding: 35px 20px 20px 20px;
    height: calc(100vh - 75px);
}

.chat-container .chat-list h4 {
    margin-bottom: 14px;
    font-size: 28px;
    font-weight: 600;
}

.search-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
}

.chat-item {
    display: flex;
    align-items: center;
    padding: 10px;
    /* margin-bottom: 10px; */
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.chat-item.active {
    background-color: #EDD3FF;
}

.chat-item:hover {
    background-color: #EDD3FF;
}

.chat-details {
    flex-grow: 1;
}

.chat-details h5 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 5px;
}

.chat-details p {
    margin: 0;
    font-size: 14px;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.chat-meta {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.chat-meta .time {
    font-size: 15px;
    color: #999;
    font-weight: 500;
}

.unread-count {
    background-color: #6200ee;
    color: #FFFFFF;
    border-radius: 50%;
    font-size: 13px;
    height: 22px;
    width: 22px;
    text-align: center;
    padding-top: 2px;
    font-weight: 400;
}

.chat-window {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: #F5E8FF;
    /* height: 100vh; */
}

/* .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #555555
} */
.chat-header {
    border-bottom: 1px solid #555;
    /* justify-content: space-between; */
    padding: 15px;
    position: fixed;
    width: 100%;
    background-color: #F5E8FF;
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-info {
    display: flex;
    align-items: center;
}

img.chat-profile-pic {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    margin-right: 10px;
}

.status {
    color: #555555;
    font-size: 15px;
    margin-bottom: 0;

}

.chat-actions {
    font-size: 20px;
    margin-left: 20px;
    cursor: pointer;
    gap: 10px;
    display: flex;
    align-items: baseline;
}

.chat-body {
    flex-grow: 1;
    padding: 0 20px;
    overflow-y: auto;
    padding-top: 100px;
}

.chat-message {
    display: flex;
    margin-bottom: 20px;
}

.chat-message.sent {
    justify-content: flex-end;
}

.chat-message.received {
    justify-content: flex-start;
}

.chat-body .message-text p {
    font-size: 14px;
    font-weight: 400;
    white-space: normal;
    word-wrap: break-word;
    max-width: 300px;
}

.chat-body .chat_messages_scroll {
    overflow-y: auto;
    overflow-x: hidden;
    /* scrollbar-width: none; */
    margin-bottom: 5px;
}

.message-content {
    display: flex;
    max-width: 50%;
    gap: 20px;
}

.chat-message.sent .message-content {
    justify-content: flex-end;
}

.chat-message.received .message-content {
    width: 100%;
}

.profile-pic-small {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    flex-shrink: 0;
}

.message-text {
    background-color: white;
    padding: 10px 15px;
    border-radius: 10px;
    width: fit-content;
}

.message-text p {
    margin: 0;
}

.chat-message.sent .message-text {
    background-color: #b481f9;
    color: white;
}

.message-input {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #ccc;
    margin: 3px 10px 16px 10px;
    /* height: 45px; */
}

.message-input textarea {
    width: 90%;
    border: none;
    outline: none;
    resize: none;
    overflow: hidden;
    overflow-y: scroll;
    max-height: "72px";
    scrollbar-width: none;
}

/* .message-input input {
    flex-grow: 1;
    padding: 10px;
    border: none;
    outline: none;
} */

.header-name {
    font-weight: 500;
    font-size: 18px;
}

.searchbar {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 7px 24px 10px 7px;
    margin-bottom: 21px;
    border-radius: 30px;
    border: 1px solid #edd3ff;
}

.searchbar input {
    border: none;
    outline: none;
    margin-left: 10px;
    flex: 1;
    font-size: 13px;
    font-weight: 400;
}

.chat-tabs {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid #EDD3FF;
    width: 100%;
    /* gap: 40px; */
}

.chat-type {
    background: #6A0DAD;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    border-radius: 13.5px;
    padding: 0 20px;
    height: fit-content;
    width: fit-content;
    font-family: "poppins";
}

.chat-deselect {
    font-family: "poppins";
    border: 1px solid #EDD3FF;
    color: #555;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    border-radius: 13.5px;
    padding: 0 20px;
    height: fit-content;
    width: fit-content;
}

.chat-items {
    overflow-y: scroll;
    height: calc(100vh - 240px);
    overflow-x: hidden;
}

.chat-dot {
    background: #3CB371;
    padding: 5px;
    position: absolute;
    border-radius: 50%;
    bottom: 0;
    right: 15px;
}

.chat-image {
    position: relative;
}

.chat-container {
    display: flex;
    margin-top: 74px;
    height: calc(100vh - 74px) !important;
}

.message-dummy-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
}

.no-chat-selected h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
}

.no-chat-selected p {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #555555;
}

.chat-message.sent .message-content .message-timer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.msg-time {
    font-size: 9px;
    line-height: 19.5px;
    color: #555555;
}

.chat-message.received .message-content .message-timer .msg-time {
    display: flex;
    justify-content: flex-end;

}

.chat-container .chat-list h4 svg,
.chat-header svg {
    display: none;
}

.date-display {
    text-align: center;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #555555;
}

.Request-message {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding: 10px 20px;
    border-radius: 30px;
    border: 1px solid #ccc;
    margin: 3px 10px 16px 10px;
}

.Accept-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

.Accept-content {
    font-size: 12px;
    color: #a8a8a8;
    line-height: 12px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 5px;
}

.request-buttons {
    display: flex;
    justify-content: center;
    border-top: 0.5px solid #ccc;
    padding: 10px 5px 5px 5px;
}

@media screen and (max-width: 767.98px) {
    .chat-body {
        padding-top: 100px;
    }

    .chat-container {
        margin-top: 54px;
        width: 100%;
    }

    .chat-container .chat-list h4 {
        margin-bottom: 26px;
        font-size: 18px;
        display: none;
    }

    .chat-list {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding-top: 15px;
    }

    .searchbar {
        border: 1px solid #6A0DAD;
        padding: 7px 24px 10px 7px;
        margin-bottom: 8px;
    }

    .chat-type,
    .chat-deselect {
        border-radius: 17px;
        padding: 0px 10px;
    }

    .chat-profile-pic {
        width: 40px;
        height: 40px;
    }

    .chat-details h5 {
        font-size: 15px;
        font-weight: 500;
    }

    .chat-details p {
        font-size: 13px;
    }

    .chat-meta .time {
        font-size: 13px;
        font-weight: 400;
    }

    .chat-meta .time {
        font-size: 10px;
    }

    .chat-tabs {
        padding-bottom: 8px;
    }

    .chat-container .chat-list h4 svg,
    .chat-header svg {
        display: inline-block;
    }

    .message-input {
        /* border-radius: 0; */
        margin: 0;
        position: fixed;
        width: 100%;
        bottom: 0px;
    }

    .chat-body .message-text p {
        max-width: 250px;
    }

    .message-input textarea {
        width: 80%;
    }

    .chat-items {
        padding-bottom: 65px;
    }

    .send-position {
        position: absolute;
        right: 20px;
    }

    .chat_messages_scroll {
        padding-bottom: 50px;
    }

    .message-content {
        max-width: 100%;
    }

}

@media screen and (max-width: 991.98px) {
    /* .chat-window {
        height: 100vh;
    } */

    .chat-container {
        margin-top: 54px;
    }

    .chat-items {
        height: calc(100vh - 150px);
    }
}