.edit-profile-container {
  position: relative;
}

.edit-profile-container .avatar-editor {
  position: relative;
}

.edit-profile-container .remove-image {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: red;
  z-index: 10; /* Ensure it's on top of the image */
}

.edit-profile-container .controls-container {
  position: absolute;
  bottom:0px;
  left: 22px;
  display: flex;
  align-items: center;
  z-index: 5;
}
.edit-profile-container .zoom-slider {
  display: flex;
  align-items: center;
}

.edit-profile-container .rotate-icon {
  position: absolute;
  bottom:0px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #555;
  z-index: 5;
}
.edit-profile-container {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center align horizontally */
  overflow: hidden;
  padding: 20px; /* Optional padding for spacing */
}

.avatar-editor {
  width: 100%; /* Make the avatar editor take up full width */
  max-width: 600px; /* Optional: limit the maximum width */
  max-height: 400px; /* Set a max height for the avatar editor */
  margin-bottom: 20px; /* Space between avatar editor and textarea */
  object-fit: contain; /* Ensure the image fits nicely within the container */
}

.textarea {
  width: 100%;
  margin-top: 10px;
  max-width: 700px;
 
}