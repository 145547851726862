.banner-img img {
    max-width: calc(100% - 30px);
    border-radius: 10px;
}
.banners-container {
    padding: 30px 0px;
}
.stories-container {
    max-width: calc(100vw - 700px);
    padding: 0px 15px;
    margin-left: auto;
    margin-right: auto;
}
.story-item img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

@media screen and (max-width: 767.98px) {
    .banner-img img {
        max-width: 100%;
        padding: 20px;
    } 
}