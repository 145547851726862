.info-banner {
    padding: 30px 0px;
    background: radial-gradient(circle, rgba(168, 40, 139, 1) 0%, rgba(131, 51, 201, 1) 100%);
}

.flex-between-align {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info-banner-container p {
    text-align: center;
    margin: 0;
    color: #fff;
    font-weight: 400;
    font-size: 24px;
}

.top-info-container {
    padding: 10px 0px;
    border-bottom: 1px solid #ddd;
}

.top-info-container-mobile {
    padding-top: 10px;
    padding-bottom: 5px;
}

p.subcategory-title {
    color: #333;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0;
}

.top-info-container label {
    margin-top: 5px;
}

.gridlayout .categories-container {
    display: grid;
    grid-template-columns: auto auto auto;
}

.gridlayout .subcat-item-container {
    border: 1px solid #ddd;
    margin-right: 20px;
    margin-bottom: 20px;
}

.subcategories-list.gridlayout {
    padding: 20px;
}

form.login-form {
    padding: 10px 60px;
}

form.login-form p {
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
}

button.btn.btn-next {
    background-color: #3cac47;
    color: #fff;
    width: 100%;
}

.product-item a {
    color: #000;
    text-decoration: none;
}

.user-wallet {
    background-color: #f9f9f9;
    color: #333;
    border-radius: 3px;
    border: 1px solid #eee;
    margin: 16px 16px 40px;
    padding: 16px;
}

.user-wallet__amount {
    color: #000;
    font-size: 32px;
    font-weight: 500;
    margin-top: 4px;
}

.user-wallet__balance {
    color: #666;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    padding: 12px 0 16px;
    text-align: center;
}

.profile-nav__details-block {
    padding: 30px 0;
    text-align: center;
    border-bottom: 1px solid #eee;
}

.profile-nav__details-block .account-profile__img {
    height: 90px;
    width: 90px;
}

.profile-nav__details-block .account-profile__phone {
    font-size: 12px;
    padding-top: 10px;
}

.profile-nav__list {
    line-height: 53px;
    list-style: none;
}

.list-unstyled {
    margin-bottom: 0;
    margin-top: 0;
    list-style: none;
    padding-left: 0;
}

.profile-nav__list-item {
    border-top: 1px solid #eee;
    cursor: pointer;
    font-size: 14px;
    display: list-item;
    color: rgb(102, 102, 102);
    text-decoration: none;
}

.profile-nav__list-item .item-text {
    padding-left: 0 20px;
}

.my-profile__right_rn {
    border-left: 1px solid #eee;
}

.my-profile_rn {
    margin-top: 100px;
    background-color: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.my-profile_mobile {
    background-color: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.my-wallet {
    background-color: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
}

.profile-nav__list-item-active {
    border-top: 1px solid #eee;
    cursor: pointer;
    font-size: 14px;
    display: list-item;
    color: #333;
    background-color: #f9f9f9;
    text-decoration: none;
}

.no-address {
    margin: 0 auto;
    padding-top: 82px;
    text-align: center;
}

.no-address__heading {
    color: #333;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.no-address__sub-heading {
    color: #999;
    font-size: 14px;
    margin-bottom: 30px;
}

.btn--link {
    background: #0c831f;
    border-radius: 3px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: inherit;
    font-size: 14px;
    outline: none;
    padding: 10px 10px;
    text-align: center;
    margin-bottom: 10px;
}

.btn--link:hover {
    background: white;
    color: #0c831f;
    border: 1px solid #0c831f;
}

.my-addresses {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 3px;
    margin: 26px 30px;
}

.my-addresses__new-address-btn {
    border: none;
    font-size: 13px;
    padding: 16px 20px;
    text-align: left;
    width: 100%;
    color: #0c831f;
    cursor: pointer;
}

.my-addresses__new-address-btn-checkout {
    border: none;
    font-size: 13px;
    padding: 16px 20px;
    text-align: left;
    width: 100%;
    color: #0c831f;
    border: 1px solid #0c831f;
    background-color: #f9f9f9;
}

.add-icon {
    color: #0c831f;
}

.my-addresses__new-address-btn:hover {
    background-color: #f9f9f9;
}

.address-item-container {
    background-color: #fff;
    border-top: 1px solid #eee;
    padding: 16px 20px;
}

.address-item {
    font-size: 14px;
}

.address-item__label {
    color: #333;
    font-weight: 500;
    line-height: normal;
    margin-left: 36px;
}

.address-item__value {
    color: #666;
    line-height: normal;
    margin-left: 36px;
    margin-top: 8px;
}

.text--dark-gray {
    color: #333
}

.Action-address {
    border-bottom: 1px solid black;
    cursor: pointer;
}

.address-title {
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
}

.addres-subtitle {
    color: rgb(130, 130, 130);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 10px;
}

.select-control {
    color: rgb(184, 184, 184);
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-bottom: 16px;
    border-radius: 8px
}

.form-main {
    margin-top: 24px;
    margin-bottom: 10px;
}

.save-address {
    color: rgb(130, 130, 130);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 9px;
}

.address-1 {
    background: rgb(255, 255, 255);
    padding: 6px 16px;
    border: 1px solid rgb(214, 214, 214);
    border-radius: 8px;
    box-shadow: rgba(28, 28, 28, 0.06) 0px 1px 4px;
    text-align: center;
    color: rgb(28, 28, 28);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
}

.address-2 {
    background: rgb(255, 255, 255);
    padding: 6px 16px;
    border: 1px solid rgb(214, 214, 214);
    border-radius: 8px;
    border: 1px solid rgb(49, 134, 22);
    box-shadow: rgba(28, 28, 28, 0.06) 0px 1px 4px;
    text-align: center;
    color: rgb(28, 28, 28);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
}

.btn-address {
    padding: 8px 16px;
    background-color: rgb(49, 134, 22);
    color: rgb(255, 255, 255);
    font-size: 17px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
}

.btn-address:hover {
    background-color: white;
    color: rgb(49, 134, 22);
}

.change-location-title {
    font-family: okra;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.non-serviceable-step {
    text-align: center;
    width: 100%;
}

.ns--image {
    width: 168px;
    margin-bottom: 6px;
}

.ns-exclamation {
    color: #333;
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 4px;
    font-weight: 500;
}

.location__separator {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    height: 19px;
    padding: 16px 12px;
    overflow: hidden;
    text-align: center;
}

.modal-back {
    color: #0c831f;
    margin-left: 0;
    cursor: pointer;
}

.oval-container {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.oval {
    width: 28px;
    height: 28px;
    border: solid 1px #ccc;
    background-color: #f9f9f9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    position: relative;
}

.oval .separator-text {
    position: relative;
    display: inline-block;
}

.or {
    width: 16px;
    height: 13px;
    font-family: okra;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #bbb;
    text-align: center;
}

.bill-details {
    padding: 0px 16px;
    background-color: rgb(255, 255, 255);
}

.bill-head {
    color: rgb(0, 0, 0);
    margin-bottom: 12px;
    font-size: 15px;
    font-family: Okra-Bold;
    font-weight: 700;
    line-height: 20px;
    background: rgb(255, 255, 255);
    padding-top: 12px
}

.bill-status {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    justify-content: space-between;
    align-items: center;
}

.mrp-head {
    padding: 2px 0px;
    color: rgb(102, 102, 102);
}

.grand-total {
    display: flex;
    margin-top: 12px;
    padding-bottom: 16px;
    justify-content: space-between;
    align-items: center;
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: bold;
    font-family: Okra-Medium;
}

.cuppons-aply {
    color: rgb(130, 130, 130);
    padding: 8px 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: rgb(252, 252, 252);
    font-weight: 500;
    text-align: center;
}

.CancellationPolicy {
    font-size: 15px;
    line-height: 20px;
    font-family: Okra-bold;
    font-weight: 700;
    padding-top: 12px;
    padding-bottom: 4px;
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
}

.CancellationPolicy-content {
    font-family: Okra;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: rgb(130, 130, 130);
    background-color: rgb(255, 255, 255);
}

.pac-container {
    z-index: 5000;
}

.add-address-main {
    background-color: rgb(255, 255, 255);
}

.cart-address {
    display: flex;
    align-items: center;
    gap: 20px;
}

.left-arrrow {
    font-size: 24px;
    color: rgb(0, 0, 0);
    font-family: CustomFont;
    cursor: pointer;
}

.left-address {
    font-size: 16px;
    line-height: 20px;
    font-family: Okra;
    font-weight: 700;
    color: rgb(31, 31, 31);
}

.add-address-nav {
    cursor: pointer;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 16px;
}

.add-icon-nav {
    font-size: 40px;
    color: rgb(12, 131, 31);
}

.address-head-nav {
    color: rgb(0, 0, 0);
    line-height: 20px;
    font-size: 16px;
    font-family: Okra-Regular;
}

.address-p {
    padding: 16px;
    background-color: rgb(238, 238, 238);
    line-height: 16px;
    font-size: 12px;
    color: rgb(153, 153, 153);
    font-family: Okra-Regular;
}

.address-ass {
    margin-bottom: 8px;
    line-height: 20px;
    font-size: 16px;
    font-family: Okra-Bold;
    color: rgb(0, 0, 0);
}

.address-detail {
    line-height: 16px;
    font-size: 15px;
    color: rgb(102, 102, 102);
    font-family: Okra-Regular;
}

.address-main {
    padding: 16px
}

.change-nav {
    cursor: pointer;
    color: rgb(12, 131, 31);
}

.payments-heading {
    color: rgb(28, 28, 28);
    font-size: 24px;
    font-family: Okra-Medium;
    font-weight: 600;
    line-height: 32px;
    padding: 12px 2px;
}

.checkout-left-payment {
    background-color: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.checkout-cart {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.checkout-step--address {
    padding: 10px;
}

.checkout-step-name {
    font-size: 18px;
    font-weight: 500;
    vertical-align: middle;
}

.selected-address {
    margin-top: 6px;
    color: rgb(153, 153, 153);
    overflow-wrap: break-word;
}

.save-address-ass {
    font-weight: 500 !important;
}

.checkout-card-main {
    display: flex;
    vertical-align: middle;
    align-items: center;
}

.checkot-cart-title {
    font-weight: 100;
}

.checkout-img {
    padding: 0 12px;
    width: 84px;
    height: 57px
}

.checkout-cart-itme-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.button-checkout {
    height: 52px;
    cursor: pointer;
    background-color: rgb(67, 121, 40);
    color: white;
    font-size: 18px;
}

.button-checkout-disbled {
    height: 52px;
    cursor: pointer;
    background-color: rgb(204, 204, 204);
    color: white;
    font-size: 18px;
}

.payment-main {
    margin-top: 100px;
}

.relclass {
    position: relative;
}

.subcategories-list {
    position: sticky;
    top: 100px;
}

.subcategories-list-mobile {
    position: sticky;
    top: 200px;
}

.pad-10 {
    padding-top: 10px;
}

.color-error {
    color: red;
}

.hide-for-desktop {
    display: none;
}

.hide-for-mobile {
    display: block;
}

.mobile-filter-pop .modal-content {
    height: calc(100vh - 20px);
    overflow-y: scroll;
}

button.btn.close-btn {
    position: absolute;
    right: 5px;
    top: 5px;
}


.mobile-filter-pop button.btn.btn-primary {
    width: 100%;
    position: sticky;
    bottom: 20px;
    left: 20px;
}

.inlineContainer {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 8px 12px;
    margin-top: 15px;
    /* overflow-y: hidden;
    overflow-x: auto; */
    white-space: nowrap;
    -ms-overflow-style: none;
    /* scrollbar-width: none;
    -webkit-overflow-scrolling: touch; */
    position: sticky;
    top: 75px;
    z-index: 10;
    width: calc(100vw - 235px);
    /* background-color: white; */
}

.inlineContainer::-webkit-scrollbar {
    display: none;
}

.inlineContainer .inlinBpContainer {
    margin-left: 1rem;
    display: flex;
    gap: 1rem;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
}

.inlineContainer .inlinBpContainer .btn:hover {
    box-shadow: 0 .33333px 1.5px rgba(0, 0, 0, .039), 0 1.25px 5px rgba(0, 0, 0, .19);
}

.inlineContainer .inlinBpContainer .inlineBp {
    color: #fff !important;
    background: #465986 !important;
    border-radius: 4px;
    padding: 8px;
    text-transform: capitalize;
    margin-right: .5rem;
    cursor: pointer;
}

.inlineContainer::-webkit-scrollbar {
    display: none;
}

.forgot-pwd {
    padding-bottom: 10px;
    float: right;
    font-size: 13px;
    color: #6A0DAD;
    font-weight: 400;
    text-decoration: none;
}

.profile-title .message-btn {
    margin-bottom: 0;
    border-color: #6A0DAD;
}

button.btn.btn-primary {
    border-radius: 30px !important;
    background-color: #6A0DAD;
    border-radius: 4px;
    border: 1px solid #6A0DAD;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "poppins";
}

button.btn.btn-primary:hover {
    background: #6A0DAD;
    color: #fff;
    border: 1px solid;
    border-radius: 4px;
}

.css-9jq23d {
    color: #272529 !important;
    font-weight: 600;
}

.css-olqui2-singleValue {
    color: #272529 !important;
    font-weight: 600;
}

.popup-sheet {
    background: #fcfcfd;
    min-height: 92vh;
    inset: 0px;
    transform: translate3d(0px, 0px, 0px);
}



.categarios-sub {
    margin-top: 100px;
    background-color: #f9f9f9;
    position: sticky;
    top: 100px;
    transition: transform 0.5s ease-in-out;
    width: 100%;
    z-index: 900;
}

.categarios-sub ul {
    text-decoration: none;
}

.list {
    list-style: none;
    color: #666;
    font-size: 17px;
    margin-left: 17px
}

.cat-sub-main {
    background-color: #fff;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
    position: relative;
}

.select-sub {
    background-color: #f7f7f7;
    width: 100px;
    border: none;
}

.favour {
    text-decoration: line-through;
}

green {
    color: #0c831f;
}

.breadcrumb-item.active {
    color: #0c831f;
}

.breadcrumb {
    display: flex;
    justify-content: center;
}

.breadcrumbs h1 {
    font-size: 3rem;
    text-transform: capitalize;
    margin-top: 0;
    margin-bottom: 3.4rem;
    color: #222222;
    font-weight: 500;
}

.breadcrumb-sub {
    background-image: url("../Components/images/bg-breadcrumbs.jpg");
    padding: 3rem;
}

.breadcrumbs-product-detail {
    margin-top: 100px;
    background-image: url("../Components/images/bg-breadcrumbs.jpg");
    padding: 4rem;
}

.profile-title a,
.profile-titles a {
    color: #000;
    text-decoration: auto;
    text-transform: capitalize;
    font-size: 16px;
}

.profile-titles a {
    font-weight: 600;
    font-size: 16.5px;
    line-height: 27px;
}

.filters .filter-title {
    text-decoration: underline;
    font-size: 16px;
}

.filters {
    text-align: left;
}

.crafts-table {
    padding: 15px;
}

.crafts-table .table {
    margin-top: 15px;
}

.filters p {
    padding: 10px 20px;
    margin: 0;
}

.filters a {
    color: #000;
    text-decoration: auto;
}

.filters p:hover,
.filters p.active {
    background: #caf0dc;
    max-width: 100%;
}

.profile-header .dp {
    width: 186px;
    height: 186px;
    border-radius: 50%;
    background: linear-gradient(239deg, #EF443B, #FFD700) border-box;
    border: 2px solid transparent;
}

.profile_hideformobile {
    display: block;
    display: flex;
    column-gap: 12px;
    align-items: center;
}

.profile_hidefordesktop {
    display: none;
}

.profile-header {
    padding: 40px 0px 20px 60px;
}

.profile-details .profile_header {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 26px;
}

.profile-details .verified_member {
    width: 15px;
    height: 15px;

}

.profile-details .profile_hideformobile .profile_button {
    border: 1.5px solid #6A0DAD !important;
    padding: 4px 18px !important;
    font-size: 15px;
    font-weight: 500;
}

.profile-details .profile_follows .profile_button {
    border: 1.5px solid #6A0DAD;
    padding: 2px 15px;
}

.profile-details .profile_follows {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.profile-details .btn-default {
    padding: 1px 7px;

}

.profile-details .profile_profession {
    font-size: 18px;
    font-weight: 500;
}

.profile-details .profile_des {
    font-size: 18px;
    margin-bottom: 15px;
}

.profile-details .profile_age {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 30px;
}

.profile-details .profile_agetext {
    font-size: 15px;
    font-weight: 500;
}

.profile-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 10px; */
}

.profile-info p {
    font-size: 16px;
    margin-bottom: 0px;
    text-transform: capitalize;
}

button.btn.btn-default {
    border: 1px solid #EDD3FF;
}

.followers-info {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
}

.post-grid .post-item {
    padding: 2px;
}

.my-masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
}

.my-masonry-grid_column {
    background-clip: padding-box;
}

.my-masonry-grid_column>div {
    background: white;
    margin-bottom: 0px;
}

.my-masonry-grid .post-img {
    width: 100%;
    max-height: none;
}

.display-bio {
    padding: 20px 40px;
}

.projects-container {
    padding: 20px 60px;
}

.form-check-input[type=radio] {
    margin-right: 5px;
}

.form-group label {
    text-transform: capitalize;
}

.content-area .step_process {
    position: relative;
    width: 75%;
    margin: 0 auto;
}

.content-area .step_process::after {
    content: '';
    position: absolute;
    bottom: 18px;
    left: 50%;
    transform: translateX(-50%);
    width: 98%;
    height: 1px;
    background-color: #90CC90;
    z-index: -1;
}

.crafts-grid {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    height: calc(100vh - 250px);
    overflow-y: scroll;
}

p.nosearchresults {
    text-align: center;
    padding-top: 60px;
    font-size: 30px;
    color: #616161;
    line-height: normal;
}

.craft-item {
    padding: 10px;
    margin: 10px;
    border-radius: 13px;
    text-align: center;
    cursor: pointer;
    height: fit-content;
    background: #EDD3FF;
    border: 1px solid #6A0DAD;
    position: relative;
}

.craft-check {
    position: absolute;
    top: 5px;
    right: 5px;
}

.craft-title .btn-close {
    margin: 0;
}

.craft-item.selected {
    background: #6A0DAD;
    color: #fff;
}

.craft-item p {
    margin-bottom: 0px;
}

.form-render {
    padding: 30px 60px;
}

.arrowicon {
    text-align: left;
}

.search-screen-container {
    display: flex;
}

.filters-container {
    max-width: 300px;
    padding: 0px 15px;
    margin-bottom: 20px;
    margin-top: 75px
}

.modal-header ul.modal-tabs.nav.nav-tabs {
    display: flex;
    justify-content: center;
    width: 100%;
}

.filters-container button {
    width: 100%;
}

.profile-tablinks {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.tab-content {
    border-top: 1px solid #ddd;
}

ul.nav.custom-nav-tabs {
    justify-content: space-between;
}

.profile-tablinks .nav-link {
    color: #666;
    position: relative;
    padding-bottom: 5px;
    font-size: 15px;
    font-weight: 400;
}

.profile-tablinks .nav-link.active {
    color: #000;
    font-weight: 500;
}

.profile-tablinks .nav-link.active::after {
    content: '';
    position: absolute;
    bottom: -1.7px;
    left: 0;
    width: 100%;
    height: 2.8px;
    background-color: #6A0DAD;
    border-radius: 10px;
}

.profile-tablinks a {
    color: #666;
    text-decoration: auto;
}

.profile-tablinks .active p {
    border-bottom: 2px solid #6A0DAD;
}

.profile-tablinks p {
    margin: 0;
    text-transform: uppercase;
    font-size: 16px;
    padding-bottom: 10px;
}

.about-container {
    border-top: 1px solid #ddd;
}

.editabledp {
    position: relative;
    width: 50%;
}

.dp {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.bottom-nav-img {
    border-radius: 50%;
    max-width: 32px;
    max-height: 32px;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 80px;
    cursor: pointer;
}

.user-text-name .tooltiptext {
    visibility: hidden;
    min-width: 100%;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 34px;
    left: 50%;
    transform: translateX(-50%);
}

.user-text-name:hover .tooltiptext {
    visibility: visible;
}

.model-titles {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    gap: 20px;
}

.follow-post {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(106, 13, 173, 0.2);
    border-radius: 7px;
    width: 72px;
    height: 55px;
    justify-content: center;
}

.follow-post .profile_interet {
    font-size: 20px;
    font-weight: 600;
}

.follow-post p {
    font-size: 15px;
    font-weight: 400;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.editabledp:hover .dp {
    opacity: 0.3;
}

.editabledp:hover .middle {
    opacity: 1;
}

.container-position {
    position: sticky;
    top: 75px;
    background-color: white;
    border-bottom: 1px solid #CACACA;
    /* margin-bottom: 40px; */
    margin-left: 6px;
    /* padding-bottom: 15px; */
    display: flex;

}

.text {
    color: white;
    font-size: 16px;
    padding: 7px;
    background: #6A0DAD;
    border-radius: 5px;
}

.edit-profile-container {
    text-align: center;
}

.edit-profile-container img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.display-bio .form-select:disabled {
    background: #e9ecef;
}

.display-bio .css-16xfy0z-control {
    background: #e9ecef;
}

.display-bio .css-16xfy0z-control .css-1xc3v61-indicatorContainer {
    display: none;
}

.zoomaction {
    text-align: center;
}

.zoomaction input {
    height: 10px;
    margin-left: 10px;
}

.no-profile {
    text-align: center;
    margin-bottom: 60px;
}

.top-search-icon {
    color: #000;
}

.show-only-mobile {
    display: none;
}

.search-container {
    /* border: 1px solid #ddd; */
    padding: 28px 0px 0px;
    /* margin-bottom: 30px; */
}

.search-border {
    border: 0.5px solid #555555;
    border-radius: 5px;
    padding-bottom: 20px;
    position: relative;
}

.profile_promember {
    position: absolute;
    top: 13px;
    left: 15px;
    background-color: #504e4e78;
    border-radius: 3px;
    padding: 4px;
}

.search-screen-container .products-container {
    padding: 0 16px;
}

button.btn.btn-short,
button.btn.btn-shortlist {
    background-color: #fff;
    border-radius: 4px;
    color: #6A0DAD;
    border: 1.35px solid #6A0DAD;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 19px;
    font-size: 14px;
    font-weight: 400;
    font-family: "poppins";
    line-height: 22.5px;
    padding: 5px 8.5px;
}

.shortlist-icon p {
    margin-bottom: 0;
}

.privacy-policy {
    margin-top: 100px;
}

.shortlistbtn,
.shortlistedbtn {
    border: 1.5px solid #6A0DAD;
    color: #6A0DAD;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background: #6A0DAD;
    max-height: 31px;
}

.shortlistbtn {
    background: #fff;
}

.shortlistedbtn p,
.shortlistbtn p {
    margin-bottom: 0 !important;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 22.5px !important;
    padding: 3px 5px;
    /* border-left: 1px solid #6A0DAD; */
    background-color: #fff;
    color: #6A0DAD;
    border-radius: 0 5px 5px 0;
    max-height: 40px;
}

.vertical {
    border-left: 1px solid #6A0DAD;
    height: 30px;
}

.shortlisted-check {
    margin: 6px 5px;
    color: #fff;
    width: 17px;
    height: 17px;
}

.shortlist-check {
    margin: 6px 5px;
    color: #6A0DAD;
    width: 16px;
    height: 16px;
}

.filter_funcheader_mobile {
    display: none;
}

/* verificationpage2 css starts*/

.verification_container {
    padding: 20px;
}

.verification_container .verification_body h6 {
    font-size: 13px;
    font-weight: 400;
    color: #000;
    line-height: 19.5px;
    background-color: #F4D8FF;
    padding: 13px 15px;
    border-radius: 6px;
    text-align: center;
}

.verification_container .verication_steps {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75%;
    margin: 0 auto;
    margin-top: 60px;
}

.verification_container .verication_steps::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 98%;
    height: 1px;
    background-color: #90CC90;
    z-index: -1;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    background-color: #fff;
}

.verification_container .step h5 {
    position: absolute;
    width: max-content;
    top: 45px;
    font-size: 13px;
    font-weight: 500;
}

.verification_container .verification_audit {
    padding: 0 50px;
}

.verification_container .place_header {
    margin-bottom: 28px;
    position: relative;
}

.verification_container button {
    padding: 9px 0;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
}

.verification_container .id_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}

.verification_container .id_header p {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
}

.verification_container .union_header2 p {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
}

.verification_container .id_backside_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F4D8FF;
    border-radius: 10px;
    border: 1px dashed #212529;
    height: 264px;
    justify-content: center;
    row-gap: 20px;
}

.verification_container .id_backside_body .form-control {
    width: 60%;
    margin: 0 auto;
    border: 1px solid #212529;
    padding: 7px 22px;
}

.verification_container .id_main_header {
    border-bottom: 1px dashed #3CB371;
    padding-bottom: 25px;
}

.verification_container .union_header p {
    padding-top: 40px;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 23px;
}

.verification_container .id_union_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F4D8FF;
    border-radius: 10px;
    border: 1px dashed #212529;
    height: 128px;
    justify-content: center;
    row-gap: 6px;
    margin-top: 12px;
}

.verification_container .id_union_body .form-control {
    padding: 9px 23px;
    width: 60%;
}

.verification_container .union_add_item {
    margin: 50px auto;
}

.verification_container .union_add_item p {
    background-color: #F4D8FF;
    font-size: 22px;
    font-weight: 600;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto;
    column-gap: 5px;
}

.approval_body {
    background-color: #6A0DAD;
    border-radius: 23px;
}

.approval_body .approval_header p {
    text-align: center;
    color: #fff;
    padding: 15px 0;
    border-bottom: 1px solid #fff;
    font-size: 18px;
    font-weight: 500;
}

.approval_body .approval_item {
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    /* border-bottom: 1px dashed #fff; */

}

.approval_body .approval_item p {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    padding-top: 10px;
}

.approval_main_body {
    padding: 0px 68px 30px;
}

.verified_container {
    padding: 20px;
}

.filter-icon {
    border: 1px solid #000;
    transform: rotate(90deg);
}

.model-header-popup {
    display: flex;
    align-items: center;
}

.model-header-popup .posttitle {
    margin-bottom: 0;
}

.access-controls-scroll {
    height: 200px;
    overflow-y: auto;
    padding-right: 10px;
}

@media screen and (min-width: 767.98px) {
    .mobile-view-following {
        display: none;
    }

    .tab-content.desktop-mode {
        display: none;
    }

    .profile-tablinks.responsive {
        display: none;
    }

    .display-bio {
        padding: 20px;
    }
}

@media screen and (max-width: 991.98px) {
    .Audi-status .audi-filter .filter-icon {
        border: 1px solid #000;
    }

    .display-bio {
        padding: 20px 0px;
    }

    .show-only-mobile {
        display: block;
    }

    .hide-mobile {
        display: none;
    }

    .verification_details .id_main_header img {
        height: 264px;
    }

    .homescreen .post-image {
        /* margin-left: -15px;
        margin-right: -15px; */
        border: none !important;
    }

    .menu-container {
        max-width: 210px;
    }

    .mobile-view-following {
        display: none;
    }

    .posting-container.desktop-mode {
        display: block;
    }

    .edit-profile-container canvas {
        width: 100% !important;
        max-width: 768px !important;
        cursor: grab !important;
        touch-action: none !important;
    }

    .card-bottom-right .btn {
        font-size: 14px !important;
    }

    .profile-header {
        padding: 10px 15px;
    }

    .profile-header .dp {
        width: 150px;
        height: 150px;
    }

    .editabledp {
        width: 100%;
    }

    .profile-info {
        display: block;
    }

    .profile-info .btn {
        margin-right: 10px;
    }

    .show-only-desktop {
        display: none;
    }

    .model-titles {
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        justify-content: space-between;
    }

    .crafts-grid {
        grid-template-columns: auto auto auto auto;
    }

    .customtable-responsive {
        max-width: calc(100vw - 50px);
        overflow: auto;
    }

    .projects-container {
        padding: 10px 0px;
    }

    .editabledp {
        padding-top: 15px;
    }

    .followers-info {
        padding-top: 33px;
        gap: 4px;
        display: flex;
        justify-content: center;
    }

    .profile-details {
        padding-left: 30px;
    }

    button.btn.btn-shortlist,
    button.btn.btn-short {
        padding: 7px 10px;
        min-height: 26px;
        font-size: 13px;
        line-height: 19.5px;
    }

    .profile-designation {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 140px;
    }

    .profile-tablinks.responsive {
        display: none;
    }

    .profile_hideformobile {
        display: none;
    }

    .profile_hidefordesktop {
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        column-gap: 29px;
    }

    .hide-for-desktop {
        display: block;
    }

    .profile-details .followers-info {
        display: none;
    }

    .profile-header .dp {
        width: 130px;
        height: 130px;
    }

    .profile-details .profile_header {
        font-size: 20px;
        margin-bottom: 0;
    }

    .profile-details .profile_des {
        font-size: 15px;
        padding-bottom: 8px;
    }

    .profile-details .profile_profession {
        font-size: 16px;
    }

    .profile-details .profile_age {
        font-size: 15px;
        margin-bottom: 0px;
    }

    .profile-details .profile_agetext {
        font-size: 15px;
    }

    .profile-header .profile-details p {
        margin: 0;
    }

    .profile_button {
        border: 1.5px solid #6A0DAD !important;
        line-height: 22px;
        padding: 4px 8px;
    }

    .Audi-title {
        width: min-content !important;
    }

    .approval_body .approval_item p {
        font-size: 13px;
        line-height: 19.5px;
    }

    .approval_main_body {
        padding: 0px 24px 30px;
    }

    .approval_body .approval_header p {
        font-size: 15px;
        line-height: 22.5px;
        padding: 15px 13px;
    }

    .verification_container .union_add_item {
        margin: 47px auto 25px;
    }

    .verification_container .union_add_item p {
        margin: 25px auto;
    }

    .verification_container .id_union_body .form-control {
        padding: 2px 23px;
        width: 60%;
        border: 1px solid #6A0DAD;
    }

    .verification_container .id_union_body {
        height: 128px;
        row-gap: 6px;
        margin-top: 16px;
    }

    .verification_container .union_header2 p {
        font-size: 15px;
    }

    .verification_container .union_header p {
        font-size: 18px;
        padding-top: 25px;
        margin-bottom: 0;
    }

    .verification_container .id_backside_body {
        height: 224px;
        row-gap: 40px;
    }

    .verification_container .id_backside_body .form-control {
        width: 71%;
        padding: 2px 20px;
    }

    .verification_container .id_header {
        margin-bottom: 9px;
    }

    .verification_container .proof_back {
        margin-top: 23px;
    }

    .verification_container .id_header p {
        font-size: 15px;
    }

    .verification_container .step h5 {
        width: auto;
    }

    .verification_container .verication_steps {
        width: 88%;
    }

    .verification_container .verification_audit {
        padding: 0 1px;
    }

    .verified_container {
        padding: 0 15px;
    }

    .id_main_header .id_header img {
        height: 264px;
    }

    .similar_auditions_container {
        padding: 19px 12px 50px !important;
    }

    .similar_auditions_container .similar_auditions_header {
        flex-direction: column;
        padding-bottom: 25px !important;
        margin-top: 22px !important;
        text-align: center;
    }

    .similar_auditions_container .similar_auditions_header h2 {
        font-size: 22px !important;
        margin-bottom: 22px;
    }

    .similar_auditions_container .similar_auditions_grid {
        padding: 8px 0px 0 !important;
    }
}

@media screen and (max-width: 767.98px) {
    .similar_auditions_container {
        padding: 19px 12px 50px !important;
    }

    .similar_auditions_container .similar_auditions_header {
        flex-direction: column;
        padding-bottom: 25px !important;
        margin-top: 22px !important;
        text-align: center;
    }

    .similar_auditions_container .similar_auditions_header h2 {
        font-size: 22px !important;
        margin-bottom: 22px;
    }

    .similar_auditions_container .similar_auditions_grid {
        padding: 8px 0px 0 !important;
    }

    .approval_body .approval_item p {
        font-size: 13px;
        line-height: 19.5px;
    }

    .approval_main_body {
        padding: 0px 24px 30px;
    }

    .approval_body .approval_header p {
        font-size: 15px;
        line-height: 22.5px;
        padding: 15px 13px;
    }

    .verification_container .union_add_item {
        margin: 47px auto 25px;
    }

    .verification_container .union_add_item p {
        margin: 25px auto;
    }

    .verification_container .id_union_body .form-control {
        padding: 2px 23px;
        width: 60%;
        border: 1px solid #6A0DAD;
    }

    .verification_container .id_union_body {
        height: 128px;
        row-gap: 6px;
        margin-top: 16px;
    }

    .verification_container .union_header2 p {
        font-size: 15px;
    }

    .verification_container .union_header p {
        font-size: 18px;
        padding-top: 25px;
        margin-bottom: 0;
    }

    .verification_container .id_backside_body {
        height: 224px;
        row-gap: 40px;
    }

    .verification_container .id_backside_body .form-control {
        width: 71%;
        padding: 2px 20px;
    }

    .verification_container .id_header {
        margin-bottom: 9px;
    }

    .verification_container .proof_back {
        margin-top: 23px;
    }

    .verification_container .id_header p {
        font-size: 15px;
    }

    .verification_container .step h5 {
        width: auto;
    }

    .verification_container .verication_steps {
        width: 88%;
    }

    .verification_container .verification_audit {
        padding: 0 1px;
    }

    .verified_container {
        padding: 0 15px;
    }

    .Audi-title {
        width: min-content !important;
    }

    .profile-details .btn-default {
        padding: 0px 5px;
    }

    .profile_button {
        border: 1.5px solid #6A0DAD !important;
        line-height: 22px;
        padding: 4px 8px;
    }

    .profile_hideformobile {
        display: none;
    }

    .profile_hidefordesktop {
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 26px;
        column-gap: 10px;
    }

    .profile-header .dp {
        width: 82px;
        height: 82px;
    }

    .profile-details .profile_header {
        font-size: 20px;
        margin-bottom: 0;
    }

    .profile-details .profile_des {
        font-size: 15px;
        padding-bottom: 6px;
    }

    .profile-details .profile_profession {
        font-size: 16px;
    }

    .profile-details .profile_age {
        font-size: 15px;
        margin-bottom: 0px;
    }

    .profile-details .profile_agetext {
        font-size: 15px;
    }

    .profile-header .profile-details p {
        margin: 0;
    }

    .contactdetails_model_modal {
        padding: 0 !important;
    }

    .contact_shareheader .message_button {
        max-width: 100% !important;
    }

    .contact_shareheader .contact_button {
        max-width: 100% !important;
    }

    .contact_shareheader {
        flex-direction: column;
        row-gap: 15px;
    }

    .mobileauditions_bacgroung {
        background: #F0F5F8 !important;
    }

    .footer-bottom {
        margin-bottom: 70px;
    }

    .modal-size-craft.upgrade {
        max-width: 100% !important;
        width: 100% Im !important;
        left: 0 !important;
    }

    .hide-for-desktop {
        display: block;
    }

    .card-cont-detail {
        font-size: 12px !important;
    }

    .posting-container.desktop-mode {
        display: none;
    }

    .contents {
        display: block !important;
        padding-top: 20px;

    }

    .card-audi {
        margin-bottom: 13px !important;
        gap: 5px !important;
    }

    .right-panel {
        padding: 0 15px !important;
        margin-top: 20px;
    }

    .investment-box {
        max-width: none !important;
    }

    .tab-content.desktop-mode {
        height: calc(100vh - 60px);
        overflow-y: scroll;
    }

    .modal-resp-following {
        display: none;
    }

    .mobile-view-following {
        display: block;
    }

    .profile-tablinks.responsive {
        display: block;
    }

    .show-only-mobile {
        display: block;
    }

    .hide-for-mobile {
        display: none;
    }

    .crafts-grid {
        grid-template-columns: auto auto;
        height: calc(100vh - 180px);
    }

    .gridlayout .categories-container {
        display: grid;
        grid-template-columns: auto auto;
    }

    .profile-details .followers-info {
        display: none;
    }

    .follow-post {
        margin-bottom: 0;
    }

    .cat-product {
        width: 80px;
        height: 80px;
        text-align: center;
    }

    .table {
        width: 100%;
    }

    .table th,
    .table td {
        font-size: 12px;
        padding: 5px;
    }

    .apply-new-craft {
        font-size: 12px;
    }

    .profile-header .dp {
        width: 100px;
        height: 100px;

    }

    .followers-info {
        padding-top: 26px;
        gap: 10px;
        display: flex;
        justify-content: center;
    }


    .model-titles button.btn.btn-short.shortlist-icon,
    .model-titles button.btn.btn-shortlist.shortlist-icon {
        display: none;
    }

    .profile-button button.btn.btn-shortlist.shortlist-icon,
    .profile-button button.btn.btn-short.shortlist-icon {
        margin: 0;
    }

    .profile-button {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .Audi-status {
        top: -50px;
    }

    .content {
        flex-direction: column;
    }

    /* .main-cards,
    .opportunity-card,
    .card-detail {
        margin-top: 0 !important;
    } */

    .card-detail {
        padding: 10px 0 !important;
    }

    .project_title {
        margin-bottom: 13px !important;
    }

    .inlineContainer {
        /* width: calc(100vw - 30px); */
        /* margin-top: 35px; */
        margin-top: 0 !important;
        top: 0;
        flex-wrap: wrap;
        height: fit-content;
        gap: 10px;
        width: 100vw;
    }

    .inlineContainer .inlinBpContainer {
        margin-left: 0;
    }

    .filter_button_down {
        padding-bottom: 0 !important;
        background-color: none !important;
    }

    .container-position {
        top: 58px;
        padding-bottom: 17px;
        margin-left: 0;
        max-width: 100%;
        flex-direction: column;
    }

    .mobile-filter-pop.modal-responsive .modal-content {
        height: auto;
        overflow-y: visible;
    }

    .profile-tablinks {
        max-width: 100vw;
        margin-left: auto;
        margin-right: auto;
        margin-top: 18px;
    }

    .upgrade-header {
        background: #5e32a8 !important;
    }

    .profile-tablinks ul.nav.custom-nav-tabs .nav-link {
        font-size: 13px;
        padding: 0;
    }

    ul.nav.custom-nav-tabs {
        justify-content: space-between;
        margin: 0 9px;
    }

    .box-with-shadow {
        padding: 0 !important;
    }

    .email {
        margin-bottom: 16px;
    }

    .craft-save button.btn.btn-primary {
        display: flex !important;
    }

    .card-descrt-inner {
        height: 100% !important;
    }

    .card-descrt-inners {
        width: 100% !important;
        padding: 15px !important;
    }

    .card-type {
        padding: 5px 10px !important;
        margin-top: -20px !important;
    }

    .card-descrts {
        flex-direction: column !important;
        padding: 0 !important;
    }

    .card-descrt {
        padding: 15px 15px 7px 15px !important;
    }

    .Grid-page {
        background: #FFFFFF;
        box-shadow: none;
        padding: 0 !important;
    }

    .application-details {
        flex-direction: column;
        padding: 15px 10px !important;
    }

    .applicant-info {
        margin-right: 0 !important;
        width: 100%;
        border-bottom: 0.5px dashed #BEBEBE;
        padding-bottom: 15px;
    }

    .application-loc {
        border-bottom: 0.5px dashed #BEBEBE;
        justify-content: space-between;
        padding: 15px 0;
    }

    .application-container {
        background: none !important;
        padding: 0 !important;
    }

    .application-list {
        background: #F0F5F8;
        padding: 30px 15px !important;
        margin-top: 0 !important;
    }

    .applicant-image {
        width: 68px !important;
        height: 68px !important;
    }

    .applicant-meta {
        margin-right: 0 !important;
    }

    .date-end p {
        justify-content: flex-end;
    }

    .applicant-actions {
        justify-content: space-evenly !important;
        flex-direction: row !important;
        padding-top: 15px;
    }

    .main-cards .opportunty-filter .opportunty_filter_mobilenon {
        display: none !important;
    }

    .filter_funcheader_mobile {
        display: block;
    }

    .filter_funcheader_mobile .filter_funinnerbody {
        justify-content: space-between;
        align-items: center;
        display: flex;
        padding-top: 15px;
    }

    .card-style {
        margin-bottom: 7px !important;
    }

    .statuses {
        margin-bottom: 10px;
    }

    .Application-tabs {
        gap: 10px;
        align-items: center;
        height: 48px;
        padding: 8px 12px;
        overflow-y: hidden;
        overflow-x: auto;
        white-space: nowrap;
        -ms-overflow-style: none;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;
        width: calc(100vw - 30px);
        background-color: white;
    }

    .back-buttons {
        font-size: 18px !important;
        line-height: 27px !important;
        padding-bottom: 20px !important;
    }

    .applictn-dtls {
        padding: 19px !important;
    }

    .card-bottom-lefts {
        margin: 18px 0 !important;
    }

    .main-cards h6.opp-title {
        font-size: 17px;
        padding: 10px 0 1px 0;
    }

    .main-cards p.opp-quote {
        font-size: 11px;
    }

    /* .apply-content{
        margin-bottom: 21px;
    } */
    .apply-content span {
        line-height: 16px;
    }

    .apply-data {
        line-height: 18px;
    }

    .card-botton-centent {
        gap: 4px !important;
    }

    .Grid-page .back-button {
        margin: 0 12px 0 7px;
    }

    .investment-screen .bax-shadow {
        margin-bottom: 18px;
    }

    .card-footer .card-bottom-detail {
        font-size: 11px;
    }

    .footer-content p.description-footer {
        line-height: 28px;
        font-size: 16px;
    }

    .card-bottom .view-button-profile {
        margin-bottom: 10px;
    }

    .main-cards .addinformation .btnflx {
        display: none !important;
    }

    .main-cards .opportunty-filter {
        flex-direction: column-reverse;
    }

    .main-cards .Audi-status {
        width: 100%;
        display: flex;
        column-gap: 7px;
        justify-content: center;
        align-items: center;
        padding-left: 0;
    }

    .main-cards .audi-filter {
        margin: 0;
    }

    .main-cards .auditons-status-btns {
        border-radius: 20px;
        max-width: none;
    }

    .main-cards .auditons-status-btns .btn {
        border-radius: 20px !important;
    }

    .content-area .main-cards {
        margin-top: 24px;
    }

    .main-cards .opportunities {
        border: none;
        padding: 0px 20px 0px 20px;
    }

    .main-cards .opportunity-card {
        background: none;
    }

    .main-cards .opportunity-card .card-style {
        margin-bottom: 32px;
    }

    .profile-details .profile_follows {
        display: none;
    }

    .profile_mobile_follows .profile_follows {
        display: flex;
        column-gap: 26px;
        margin-top: 20px;
        justify-content: center;
    }

    .opportunites {
        width: 60% !important;
    }

    .homescreen .post-inner-container {
        max-width: 100%;
    }

    .text-overflow {
        overflow-wrap: anywhere;
    }

    .btnflx .profile_auditions_btn {
        padding-top: 10px;
    }

}

@media (min-width: 1200px) and (max-width: 1500px) {
    .custom-col {
        flex: 0 0 33.3333% !important;
        /* 3 cards per row */
        max-width: 33.3333% !important;
    }
}

@media screen and (max-width: 991.98px) {
    .search-screen-container .products-container {
        padding: 0;
    }

    .btnflx .profile_auditions_btn {
        padding-top: 10px;
    }

    .homescreen .post-inner-container {
        max-width: 100%;
    }

    .card-descrts {
        flex-direction: column !important;
        padding: 15 !important;
    }

    .right-panel {
        padding: 0 15px !important;
    }

    .investment-box {
        max-width: none !important;
    }

    .opportunites {
        width: 60% !important;
    }

    .profile_mobile_follows .profile_follows {
        display: flex;
        column-gap: 7px;
        margin-top: 20px;
        align-items: center;
        justify-content: center;
    }

    .profile-details .profile_follows {
        display: none;
    }

    .main-cards .opportunty-filter .opportunty_filter_mobilenon {
        display: none !important;
    }

    .filter_funcheader_mobile {
        display: block;
    }

    .filter_funcheader_mobile .filter_funinnerbody {
        justify-content: space-between;
        align-items: center;
        display: flex;
        padding-top: 15px;
    }

    .card-style {
        margin-bottom: 15px;
    }

    .opportunity-card .card-bottom {
        margin-top: 17px;
    }

    .apply-content span {
        line-height: 16px;
    }

    .apply-data {
        line-height: 21px;
    }

    .card-bottom .card-bottom-detail {
        font-size: 11px;
        display: flex;
        align-items: center;
    }

    .card-botton-centent {
        gap: 4px !important;
    }

    .Grid-page .back-button {
        margin: 0 12px 0 7px;
    }

    .card-footer .card-bottom-detail {
        font-size: 11px;
    }

    .content-area .Grid-page {
        width: 100%;
        padding: 0;
        padding-top: 50px;
    }

    .card-content .card-descrt-inners {
        width: 100%;
    }

    .footer-content p,
    .footer-content ul li a {
        line-height: 28px;
        font-size: 16px;
    }

    .investment-screen .bax-shadow {
        margin-bottom: 18px;
    }

    .project_title {
        margin-bottom: 13px;
    }

    .card-content .card-audi {
        gap: 5px;
    }

    .card-content .card-detail {
        margin-top: 13px;
    }


    .main-cards .addinformation .btnflx {
        display: none !important;
    }

    .main-cards .opportunty-filter {
        flex-direction: column-reverse;
    }

    .main-cards .Audi-status {
        width: 100%;
        padding-left: 0;
    }

    .main-cards .auditons-status-btns {
        border-radius: 20px;
        max-width: none;
        padding: 0px;
        max-height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .main-cards .auditons-status-btns .btn {
        border-radius: 30px !important;
        padding: 10px 10px;
        line-height: 13px;
    }

    /* .content-area .main-cards{
        margin-top: 28px;
    } */
    .main-cards .opportunities {
        border: none;
    }

    .main-cards .opportunity-card {
        background: none;
    }

    .main-cards .opportunity-card .card-style {
        margin-top: 15px;
        margin-bottom: 30px;
    }

    .main-cards h6.opp-title {
        font-size: 17px;
    }

    .main-cards p.opp-quote {
        font-size: 12px;
    }

    .main-cards .btnItem1 {
        padding-top: 8px !important;
    }

    .commentlist {
        position: relative;
        height: 88vh;
    }

    .open-comments-desktop {
        height: 80vh !important;
    }
}

.upgrade-header h4 {
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 10px;
}

.upgrade-header .pro_exclusive {
    font-size: 25px;
    font-weight: 500;
    font-family: "Akaya Kanadaka", system-ui;
    padding-bottom: 40px;
}


.custom-chnage-dir {
    align-items: center;
    margin-right: 10px;
}

.application-loc {
    display: flex;
}

.investment-screen .col-xl-4.col-lg-4.col-md-6.col-sm-12.col-12 {
    margin-bottom: 10px;
}

.craft-save button.btn.btn-primary {
    display: none;
}

.card-style {
    position: relative;
    overflow: visible;
    cursor: pointer;
    margin-top: 40px;
    display: flex;
}

.card-styles {
    color: #000;
    position: relative;
    overflow: visible;
    cursor: pointer;
}

h4.banner-title {
    color: rgb(0, 0, 0);
    margin: 9px 34px 10px 8px;
    font-size: 18px;
    line-height: 20px;
    text-transform: capitalize;
    text-align: center;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 22px;
    white-space: nowrap;
}

.opportunities {
    /* padding: 0px 20px 10px;
    width: 100%;
    border-bottom: .5px solid #ddd;
    position: relative; */
    padding: 40px 35px 19px 40px;
    border-bottom: 1.3px solid #6a0dada1;
}

h6.opp-title {
    margin: 0;
    font-size: 24px;
    line-height: 32px;
    color: #212529;
    font-weight: 600;
}

p.opp-quote {
    font-size: 13px;
    line-height: 16px;
    color: #676767;
    margin: 0;
}

/* .main-cards {
    margin-top: 50px;
} */

.opportunity-card {
    padding: 20px;
    background: #F0F5F8;
}

.btnflx .btnitem {
    width: 100%;
    min-width: 113px;
}

.btnflx .profile_auditions_btn {
    display: flex;
    justify-content: end;
}

.opportunities .audi-filter button {
    border: none;
}

.opportunities .audi-filter button img {
    width: 29px;
}

.auditionList {
    color: #000;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.auditionList-require {
    max-height: 70px;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 20px 0;
}

.card-type {
    background: #7a7acc;
    padding: 1px 15px;
    margin-bottom: 0px;
    color: #fff;
    border-radius: 5px;
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
    font-family: "poppins";
    max-width: max-content;
    margin-top: -15px;
}

.card-type-grid {
    background: #7a7acc;
    padding: 5px 15px;
    margin-bottom: 0px;
    color: #fff;
    border-radius: 5px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    font-family: "poppins";
    max-width: max-content;
    margin-top: -20px;
}

.line {
    flex: 1;
    height: 1px;
    border-top: 1px solid rgb(229, 229, 229);
}

button.btn.btn-default.btn-extraborder {
    border-color: #26247b;
}

.btnflx .btnitem button.btn.btn-default {
    border-color: #26247b;
}

.card-audi {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
}

.card-descrt {
    padding: 15px 15px 10px 15px;
    border-radius: 0 0 10px 10px;
    display: flex;
    background-color: #ffffff;
    flex-grow: 1;
    justify-content: space-between;
    flex-direction: column;
    color: #212529;
    font-family: "poppins";
}

.card-descrts {
    flex-direction: row;
    padding: 30px 25px;
}

.card-descrt-inner {
    overflow-y: auto;
    scroll-behavior: smooth;
    height: 400px;
    overflow-x: hidden;
    scrollbar-width: none;
}

.card-bottom-detail {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    color: #6C6C74;
}

.card-descrt-inners {
    padding: 0px 30px 10px 5px;
    width: 540px;
}

.auditionLists {
    padding-top: 25px;
}

.card-bottom {
    border-top: 1px solid #ddd;
    background: #fff;
    /* margin-top: 20px; */
    /* color: #000; */
    /* border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px; */
    /* padding: 5px; */
}

.require-text {
    font-size: 10px;
    font-weight: 700;
}

.card-bottom-right .btn {
    margin-left: 5px;
}

.card-bottom-left {
    display: flex;
    flex-direction: column;
}

.card-bottom-lefts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 20px 0;
    /* gap: 10px; */
}

.view-button-profile {
    margin-bottom: 14px;
}

.view-button-profile .contact_query {
    font-size: 10px;
    color: #212529;
    text-align: center;
    font-weight: 400;
    margin: 0;
    padding-top: 14px;
    line-height: normal;
}

.view-button-profile .contact_query span {
    font-size: 11px;
    color: #000;
    text-align: start;
    font-weight: 500;
    padding-right: 3px;
}

.card-bottom-right {
    display: flex;
}

.card-botton-centent {
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: flex-start;
}

.card-bottom-right-detail {
    flex: 1;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    padding-right: 16px;
}

.card-bottom-right-details {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 16px;
}

.card-bottom-right .btn {
    font-size: 16px;
}

.card-bottom-right button {
    margin: 2px;
}

.card-detail {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 5px 0;
    border-bottom: 0.5px dashed #BEBEBE;
    border-top: 0.5px dashed #BEBEBE;
    column-gap: 15px;
}

.card-cont-title {
    color: #212529;
    font-size: 15px;
    line-height: 17px;
    font-weight: 500;
    text-align: left;
}

.card-cont-detail {
    font-weight: 400;
    color: #65657C;
    line-height: 19.5px;
    font-size: 13px;
    text-align: center;
}

.contact-dtls {
    display: flex;
    justify-content: center;
    column-gap: 5px;
}

.applictn-dtls {
    display: flex;
    flex-direction: column;
    font-size: 12.5px;
    color: #212529;
    background: #EDD3FF;
    font-weight: 400;
    border-radius: 10px;
    padding: 15px;
    margin: 5px 0;
}

.apply-content {
    display: flex;
    justify-content: space-between;
}

.apply-data {
    font-size: 13px;
    font-weight: 500;
}

.main-cards .opportunty-filter .opportunty_filter_mobilenon {
    display: flex;
    justify-content: start;

}

.opportunites {
    width: 22%;
    max-width: 190px;
}

.Audi-status {
    display: flex;
    column-gap: 7px;
    width: 67%;
    justify-content: start;
    align-items: baseline;
    padding-left: 56px;
}

.auditons-status-btns {
    max-width: 500px;
    width: 100%;
    height: max-content;
    background-color: #fff;
    border: 1px solid #0000002e;
    border-radius: 3vw;
}

.auditons-status-btns .btn:hover {
    color: none !important;
}

.Audi-status .auditons-status-btns .btn-secondary {
    width: 55%;
    border-radius: 3vw;
    background: #26247b;
    font-weight: 600;
    font-size: 19px;
}

.auditons-status-btns .btn-info {
    width: 45%;
    border-radius: 3vw;
    background: transparent;
    border-color: transparent;
    color: #26247b;
    font-weight: 600;
}

.addinformation {
    width: 15%;
    padding-top: 4px;
    min-width: 122px;
    padding-left: 35px;
}

/* .addinformation .btnflxbtn .btn-primary{
    border-radius: 30px;
} */

.btn-check:checked+.btn,
.btn.a.ctive,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    background: transparent;
    border-color: transparent;
    color: transparent;
}

.audi-filter {
    margin-top: 5px;
}

.similar_auditions_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px 35px 50px;
    background-color: #F0F5F8;

}

.similar_auditions_container .similar_auditions_start {
    display: flex;
    justify-content: center;
}

.similar_auditions_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
    margin-top: 40px;
}

.similar_auditions_header h2 {
    font-size: 24px;
    font-weight: 600;
    color: #555555;
}

.similar_auditions_header .btn_similar {
    background-color: #fff;
    color: #555555;
    font-size: 18px;
    font-weight: 600;
    padding: 4px 43px;
    border-radius: 30px;
    border: 1.3px solid #555555;
}

.similar_auditions_container .similar_auditions_grid {
    padding: 8px 17px 0;
}

.require-conten {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
    font-size: 14px;
    color: #555;
}

.investment-screen {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 15px;
    max-width: 1200px;
    margin: 0 auto;
}

.Grid-page {
    padding: 60px 35px;
}

.auditionsdetails_conatiner {
    padding: 40px 15px;

}

.back-button {
    border: none;
    color: #373737;
    font-size: 16px;
    cursor: pointer;
    margin: 0px 25px 0;
    margin: 0 12px 0 7px;
    display: flex;
    align-items: center;
    column-gap: 4px;
}

.back-buttons {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #212529;
    padding: 36px 10px 25px 35px;
}

.bax-shadow {
    box-shadow: 0px 0px 3px 0px #00000033;
    background: #FFF;
}

.auditions_box_minheight {
    min-height: 400px !important;
}

.right-panel {
    flex: 1;
    padding: 0 0 0 36px;
    display: flex;
    justify-content: center;
    height: min-content;
}

.card-title-custom {
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    color: #212529;
    line-height: 21px;
    margin-bottom: 8px;
}

.card-body .card-text {
    margin-bottom: 0;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;
    max-height: 200px;
}

.investment-box {
    width: 100%;
    max-width: 344px;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    text-align: left;
    min-width: 214px;
}

.investment-box .hide-mobile {
    margin-bottom: 14px;
}

.investment-box label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

.investment-box input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    font-size: 16px;
}

.expected-returns {
    margin-bottom: 20px;
}

.expected-returns span {
    display: block;
    font-weight: bold;
}

.expected-returns p {
    font-size: 16px;
    margin: 0;
}

.invest-now {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.invest-now:hover {
    background-color: #0056b3;
}

.investment-box a:hover {
    text-decoration: underline;
}

.Audi-require {
    color: #6A0DAD;
    text-decoration: underline;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Audi-title {
    position: absolute;
    /* left: -36px; */
    width: max-content;
    top: 45px;
    font-size: 13px;
    font-weight: 500;
}

.audi-label {
    padding: 5px 8px;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 400;
    color: #fff;
    display: inline-block;
    line-height: 21px;
}

.verif-status {
    display: flex;
    justify-content: center;
    align-items: center;
}

.verifi-text {
    text-align: center;
    font-weight: 400;
    color: #212529;
    background-color: #F4D8FF;
    border-radius: 6px;
    font-size: 13px;
    padding: 7px 25px;

}

.desig-text {
    color: red;
    text-align: center;
    margin: 20px 0;
}

button.btn.btn-secondary a {
    color: #fff;
    text-decoration: none;
}

button.btn.btn-secondary {
    border-radius: 30px !important;
}

.view-button-profile .statuses {
    border: none;
}

.audi-cont {
    display: flex;
    align-items: center;
    gap: 50px;
    margin-bottom: 10px;
}

.login-popup .login-signup-header {
    border: none;
}

.login-sign-suggestion h2 {
    font-size: 22px;
    margin-bottom: 18px;
}

.login-popup .login-signup-tabs {
    justify-content: space-around !important;
    border-bottom: none !important;
    --bs-nav-tabs-link-active-border-color: #6A0DAD !important;
}

.login-popup .login-signup-tabs .nav-link {
    border-radius: 5px !important;
    padding: 6px 27px;
    color: #000;
}

.login-popup .login-signup-tabs .nav-link.active {
    background-color: #edd3ff;
    color: #6A0DAD;
}

.Loginerror {
    padding-bottom: 16px;
    color: #FF0000;
}

.card-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.search-audi {
    display: flex;
    align-items: center;
}

.card-types {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-top: -17px;
}

.bill-details {
    background-color: #fff;
    border-radius: 15px;
    margin-bottom: 15px;
    padding: 0 16px;
}

.cart-timer {
    align-items: center;
    display: flex;
    gap: 20px;
    padding: 12px 0;
}

.cart-timer-icon {
    background-color: #f8f8f8;
    border-radius: 10px;
    padding: 12px;
}

.comment-message {
    position: absolute;
    bottom: 0;
    background: #FFF;
    width: 100%;
}

.post-new-comment {
    display: flex;
    align-items: center;
    padding: 8px 10px 8px 10px;
    gap: 15px;
    justify-content: space-between;
    background: #E9ECEF;
}

.msg-text {
    width: 80%;
    border: none;
    background: #E9ECEF;
    outline-color: #E9ECEF;
}

.msg-text:focus-visible {
    background: #FFF;
}

.send-button {
    color: #6A0DAD;
    /* text-decoration: underline; */
}

.message-label {
    position: absolute;
    border-radius: 50%;
    background: #ED1B24;
    top: -6px;
    right: -4px;
    padding: 2px 6px;
    color: #fff;
    font-size: 9px;
    text-align: center;
}

input.form-control:focus.msg-text {
    outline: 0;
    background: none;
    border: none;
    box-shadow: none;
}

img.comment-profile {
    border: 1px solid #f2f2f2;
    border-radius: 50%;
    width: 32px;
    height: 32px;
}

.replies {
    border-top: 1px solid #ccc;
    margin-left: 32px;
    padding-top: 8px;
}

.open-comments-desktop {
    /* min-height: 250px;
    max-height: 250px; */
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;
    height: 50vh;
}

.reach {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    font-weight: 600;
}

.comment-person {
    display: flex;
    flex-direction: column;
}

.comment-detail {
    font-size: 14px;
    font-weight: 600;
}

.comment-detail span {
    font-weight: 400;
    overflow-wrap: anywhere;
}

.comment-list {
    padding: 0 15px 5px 5px;
    justify-content: space-between;
}

.notification-header {
    font-size: 20;
    font-weight: 600;
}

.notification-header.active {
    font-size: 24;
    font-weight: 700;
    text-decoration: underline;
}

.modal-size-craft .modal-dialog {
    max-width: 100%;
    width: 100%;
    margin: 0;
}

.delete-cross {
    font-size: 17px;
    color: #000;
    font-weight: 600;
    width: 20px;
    margin-left: auto;
    color: #f10c38e3;
    position: absolute;
    right: 0;
    top: 0px;
    background: #bcb4b4;
    border-radius: 85%;
}

.notifi-time {
    color: #a8a8a8;
    font-weight: 400;
    font-size: 14px;
}

.audi-edit {
    position: absolute;
    top: 12px;
    right: 12px;
    color: black;
}

.audi_edit1 {
    top: 4px !important;
}

.contact-detile {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    gap: 39;
    justify-content: flex-start;
}

.data-text {
    font-weight: 400;
    margin-left: 10px;
}

a.data-text {
    color: #212529;
    text-decoration: none;
}

.audi-modal-content {
    padding: 10px 20px;
}

.fade.modal-size-craft.modal.show {
    padding: 0 !important;
}

.upgrade-content {
    height: calc(100vh - 60px);
    overflow-y: scroll;
}

.upgrade-content .h1 {
    font-size: 57px;
    font-weight: bold;
    text-align: center;
}

.toggle-container {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 15px;
}

.monthly {
    color: #666;
    font-size: 13px;
    font-weight: 700;
}

.anually {
    color: #000;
    font-size: 13px;
    font-weight: 700;
}

.discount-info {
    padding: 2px;
    background: #1069ed38;
    border-radius: 4px;
    color: #004eff;
}

.flex-item-card {
    display: flex;
    align-items: center;
    justify-content: center;
}

.plan-card.b-1 {
    border-radius: 21px 0px 0px 0px;
}

.plan-card.b-r {
    border-radius: 0px 21px 0px 0px;
}

.plan-card h4 {
    font-weight: bold;
}

.plan-card .price {
    font-size: 38px;
    color: #000;
    font-weight: 500;
}

.flex-item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #CCC;
    padding-bottom: 12px;
}

.plans-content .price {
    font-size: 15px;
    font-weight: 700;
}

.plans-content {
    width: 98%;
}

.features-list {
    list-style-type: none;
}

.features-list li {
    position: relative;
    padding-left: 25px;
}

.features-list li::before {
    content: "\2713";
    font-size: 15px;
    font-weight: 700;
    color: #000;
    position: absolute;
    left: 0;
    top: 0;
}

.card-buy {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    padding: 20px 20px 20px 20px;
    width: 100%;
}

.card-buy .btn {
    width: 100%;
}

.card-buy .flex-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
}

.plans-content .Anual-price {
    font-size: 12px;
    font-weight: 700;
    text-align: end;
    color: gray;
}

.plans-content .form-check-label {
    font-weight: bold;
}

.plans-content .price-msg {
    margin-left: 22px;
    font-size: 14px;
    font-weight: 400;
    color: #6A0DAD
}

.plans-content .form-check-label.discount {
    margin-left: 12px;
    background: #0d36fd8c;
    color: #fff;
    font-size: 12px;
    padding: 2px 5px 2px 5px;
    border-radius: 4px;
}

.plan-card {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    border: 1px solid #ccc;
    padding: 30px 10px 10px 10px;
}

.plan-card ul {
    margin-top: 30px;
    padding-left: 0px;
}

.plan-card li {
    color: #000;
    font-size: 15px;
    font-weight: 500;
}

.modal-size-craft.upgrade {
    padding-left: 0px;
    max-width: 80%;
    width: 100%;
    left: 10%;
}

.statusIndicator {
    background: #0d6efd12;
    height: 3px;
    width: 100%;
    margin: 10px 0px 10px 0px;
}

.plan-card .btn {
    width: 100%;
}

.plan-card .description {
    background: #0d6efd12;
    padding: 2px 5px 2px 5px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
}

.like-name span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 124px;
    display: inline-block;
}

.project_title {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 15px;
}

.Audi-project-title {
    font-weight: 600;
    font-size: 15px;
    color: #212529;
    line-height: 22.5px;
}

.Audi-description {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #676767;
    opacity: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.audi-id {
    font-weight: 600;
    border: 0.5px solid #555555;
    padding: 10px 5px;
    border-radius: 5px;
    background-color: #fff;
    font-size: 15px;
    font-family: 'Poppins';
    color: #212529;
}

.Require-drop {
    position: absolute;
    right: 4px;
    top: 15px;
}

.view-button-profile button.btn.btn-primary.btn-block {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: center;
    color: #fff;
    border-radius: 20px;
    height: 40px;
}

.view-button-profile button.btn.btn-primary.btn-block:hover {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: center;
    color: #6A0DAD;
    border-radius: 20px;
    height: 40px;
    background-color: #fff;
}

.feature-list {
    padding: 0px;
    list-style: none;
    margin-bottom: 30px;
}

.feature-list li p {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.feature-list li span {
    font-size: 30px;
}

.feature-list li {
    margin-bottom: 15px;
    border-radius: 10px;
    border: 1px solid #ddd;
    padding: 10px 32px;
    background-color: #F4D8FF;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cta-section {
    position: sticky;
    bottom: 0;
    text-align: center;
    background-color: #6A0DAD;
    color: white;
    padding: 20px 52px;
    box-shadow: 10px -10px 10px rgba(0, 0, 0, 0.1), -10px -10px 10px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    z-index: 99;
}

.cta-section div {
    background-color: #3CB371;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
}

.cta-section h2 {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    border-bottom: 1px solid #fff;
    padding-bottom: 25px;

}

.plan-details .plan-row span {
    font-size: 15px;
    font-weight: 500px;
    color: #fff;
    padding-bottom: 6px;

}

.plan-details .plan-row {
    border-bottom: 1px dashed #fff;
    border-radius: initial;
}

.cta-section p {
    margin: 0px;
}

.address,
.email,
.phone {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 7px 10px 0 hsla(240, 5%, 41%, .2);
    padding: 40px;
    text-align: center;
}

section.contact {
    background: #e6eefb;
    padding: 60px 0;
}

section.contact h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 43.2px;
    padding-bottom: 12px;
    position: relative;
    text-align: center;
}

/* .inner-container h2:after {
    background: #0d53ad;
    bottom: 0;
    content: "";
    display: block;
    height: 3px;
    left: calc(50% - 25px);
    position: absolute;
    width: 50px;
} */

p.contact-quote {
    margin-bottom: 30px;
    text-align: center;
}

.anchor-text {
    color: #212529;
    text-decoration: none;
}

.box-with-shadow {
    background: #ffffff;
    padding: 35px;
    text-align: center;
    border-radius: 5px;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 10px 0px;
}

.application-lists .modal-content {
    width: 90%;
    margin: auto;
}

.modal-body .alert {
    padding: 5px;
    margin-top: 5px;
    text-align: center;
}

.application-filters #react-select-5-listbox {
    position: absolute;
    top: 30px;
    z-index: 99;
}

button.btn.btn-primary.btn-block.login-btn {
    border-radius: 32px;
    background-color: #fff;
    color: #6A0DAD;
    font-size: 25px;
    font-weight: 600;
    height: 50px;
}

button.btn.btn-primary.btn-block.radius {
    border-radius: 32px;
    color: #FFF;
    font-size: 25px;
    font-weight: 600;
    height: 50px;
}

button.btn.btn-primary.btn-block.radius:hover {
    border-radius: 32px;
    color: #6A0DAD;
    font-size: 25px;
    font-weight: 600;
    height: 50px;
    background: #fff;
}

.suggestions-users {
    margin-top: 37px;
}

.searches {
    display: flex;
    align-items: center;
    border: 1px solid #6A0DAD;
    border-radius: 40px;
    padding: 5px 15px;
    max-width: 500px;
    margin: 0 auto;
    height: 40px;
}

.ivite-option {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.invite-border {
    border: 1px solid #6A0DAD;
    width: fit-content;
    padding: 8px 25px;
    border-radius: 20px;
}

.Application-tabs {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid #EDD3FF;
    padding: 10px 40px;
}

.application-container {
    background: #F0F5F8;
    padding: 35px;
    font-family: "poppins";
}

.Application-feedback {
    background: #EDD3FF;
    padding: 10px 30px;
    border-radius: 0 0 10px 10px;
}

.Application-feedback p {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}

.feedback-content {
    margin: 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 22.5px;
    color: #555555;
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.application-content {
    margin-bottom: 20px;
}

.application-details {
    border-radius: 10px 10px 0px 0px;
    background: #FFFFFF;
    border: 0.5px solid #555555;
    display: flex;
    padding: 25px 30px;
    justify-content: space-between;
}

.view-btn,
.message-btn {
    text-align: center;
    font-size: 18px;
    width: fit-content;
    line-height: 27px;
    letter-spacing: 0.02em;
    padding: 5px 12px;
    border-radius: 18px;
}

.view-btn {
    background: #6A0DAD;
    color: #fff;
    margin-bottom: 8px;
    font-weight: 500;
}

.message-btn {
    border: 1px solid #EDD3FF;
    color: #212529;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 5px;
    height: 31px;
    margin-bottom: 10px;
}

.view-btn:hover,
.message-btn:hover {
    opacity: 0.8;
}

.applicant-info {
    display: flex;
    align-items: center;
    margin-right: 70px;
    gap: 20px;
}

.applicant-details h4 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 29px;
}

.applicant-details p {
    margin: 4px 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
}

p.height {
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
}

.applicant-meta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 50px;
}

.pagination-btn {
    border: none;
}

.applicant-meta p {
    display: flex;
    margin: 4px 0;
    font-size: 18px;
    gap: 15px;
}

.application-list {
    /* margin-top: 50px; */
    overflow-y: scroll;
    height: calc(100vh - 150px);
    scrollbar-width: none;
}

.applicant-actions {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-evenly;
}

.statuses {
    font-weight: 500;
    font-size: 18px;
    line-height: 29.07px;
    color: #3CB371;
    text-transform: capitalize;
    margin-bottom: 5px;
    border: 1px solid #3CB371;
    padding: 2px 8px;
    border-radius: 20px;
}

.applicant-image {
    width: 82px;
    height: 82px;
    border-radius: 50%;
}

.Application-modal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px dashed #BEBEBE;
    padding-bottom: 15px;
    margin-bottom: 25px;
}

.application-select {
    border-radius: 16px;
    background: #6A0DAD;
    color: #fff;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.02em;
    padding: 0 0 0 15px;
}

select.application-select option {
    background: #fff;
    color: #000;
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
}

.application-search {
    display: flex;
    align-items: center;
    border: 1px solid #6A0DAD;
    border-radius: 20px;
    padding: 5px 15px;
    width: 100%;
    margin: 0 auto;
    height: 40px;
}

.more-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.more-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px 0;
    cursor: pointer;
    width: 100%;
    justify-content: center;
    border: 0.8px solid #307BC480
}

.more-item:hover {
    background-color: #EDD3FF;
}

.more-item p {
    margin: 0;
    color: #212529;
    font-size: 15px;
}

.more-item img {
    width: 32px;
    height: 32px;
}

.more-item:nth-child(5) p {
    color: red;
}

.more-modal .modal-dialog,
.password-modal .modal-dialog {
    width: 369px;
}

.password-modal .modal-body {
    padding: 0;
}

.change-password-content {
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.change-password-content h5 {
    margin-bottom: 20px;
}

.filter-btn {
    padding: 4px 20px;
    border-radius: 20px;
    gap: 15px;
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.contact_profileheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 43px;
    padding-top: 17px;
    margin-bottom: 28px;

}

.contact_informationuser {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
}

.contact_profile_right img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #000;
    padding: 3px;
}

.contact_propfileinfor {
    display: flex;
    justify-content: center;
    align-self: center;
    column-gap: 12px;
    align-items: center;
}

.contact_informationuser span {
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

.contact_profile_title h5 {
    font-size: 20px;
    font-weight: 600;
    color: #000;
}

.contact_profileheader .contact_profilefallow button {
    border: 1px solid #6A0DAD;
    padding: 0px 9px;
    border-radius: 5px;
    background: none;
    color: #6A0DAD;
}

.contact_profile_title h6 {
    font-size: 13px;
    font-weight: 400;
    color: #000;
}

.contact_shareheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 27px;
    width: 100%;

}

.contact_shareheader .message_button {

    color: #6A0DAD;
    font-size: 18px;
    font-weight: 500;
    padding: 3px 12px;
    border-radius: 20px;
    border: 1px solid #6A0DAD;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    width: 100%;
    max-width: 160px;
}

.contact_shareheader .contact_button {
    background: #6A0DAD;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding: 3px 12px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    width: 100%;
    max-width: 160px;

}

.contact_button a {
    color: #fff;
    text-decoration: none;
}

.contactdetails_model_modal {
    width: 100%;
    max-width: 100%;
    margin: 0;
}

.modal-header {
    padding: 14px 15px 14px;
    border-bottom: 1px solid #6a0dad5e;
}

.modal-body {
    border-bottom: 1px solid #6a0dad5e;
}

.filter_button_down {
    background-color: none !important;
    z-index: 99;
}

.auditions_access .people_access {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.auditions_access p,
h6 {
    margin-bottom: 0;
}

.auditions_access h6 {
    margin-bottom: 5px;
}

.current_user,
.current_users {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.auditions_access .general_access {
    margin-top: 15px;
}

.access-select .css-13cymwt-control,
.access-select .css-16xfy0z-control {
    border-style: none;
    background-color: transparent;

}

.access-select .css-1u9des2-indicatorSeparator,
.access-select .css-16xfy0z-control .css-1wy0on6 {
    display: none;
}

.Access_profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.copy-link {
    display: flex;
    border: 1px solid rgb(106, 13, 173);
    background-color: transparent;
    color: rgb(106, 13, 173);
    font-size: 14px;
    cursor: pointer;
    align-items: center;
    padding: 6px 10px;
    border-radius: 30px;
}

.copy-link svg {
    margin-right: 8px;
}

.share-button {
    color: #fff;
    background-color: #6A0DAD;
    font-size: 12px;
    cursor: pointer;
    padding: 6px 16px;
    border-radius: 30px;
    border: none;
}

.icon-link {
    color: #6A0DAD;
    margin-left: 10px;
}

.cancel-button {
    background-color: transparent;
    color: #6A0DAD;
    font-size: 12px;
    padding: 6px 16px;
    border: none;
    cursor: pointer;
    appearance: none;
}

.sort-content {
    display: flex;
    align-items: center;
    width: 130px;
    margin-right: 10px;
}

.sort-content label {
    color: #666;
    font-size: 12px;
    margin: 0;
    text-align: center;
}

.Selectbar {
    border-color: #ddd;
    border-radius: 3px;
    border-width: 1px;
    color: #6A0DAD;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 4px;
    padding-top: 4px;
}

select#sortby {
    color: #6A0DAD;
    padding-bottom: 4px;
    padding-top: 4px;
}

.notification-logo {
    width: 90px;
    height: 20px;
    margin-right: 22px;
}