.banner-img img {
    max-width: calc(100% - 30px);
    border-radius: 10px;
}

.banners-container {
    padding: 30px 0px;
}

.adpost-container {
    width: 312px;
}

.adpost-img  img {
    max-width: 100%;
    border-radius: 5px;
    overflow: hidden;
}
.adpost-item p {
    margin-bottom: 0px;
    padding: 2px 5px;
}

.shortlist.applynow .btn {
    padding: 0 10px;
    margin-top: 30px;
    border-radius: 20px;
    /* margin-bottom: 30px; */
    font-size: 16px;
    font-weight: 600;
}

@media screen and (max-width: 767.98px) {
    .banner-img img {
        max-width: 100%;
        padding: 20px;
    }
}