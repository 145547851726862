form.otp-form {
    text-align: center;
}

h4.login-success {
    background-color: #3cac47;
    color: #fff;
    padding: 60px 20px;
    text-align: center;
}

.craft-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.craft-image {
    width: 60px;
    border: 1px solid black;
    border-radius: 50%;
    padding: 5px;
}

.display-24craft {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
}

.craft-name {
    text-align: center;
}

.material-textfield {
    position: relative;
    margin-bottom: 25px;
}

.material-textfield label {
    position: absolute;
    font-size: 1rem;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    color: gray;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: 0.1s ease-out;
    transform-origin: left top;
    pointer-events: none;
}

.material-textfield input,
.material-textfield select {
    width: 100%;
    font-size: 1rem;
    outline: none;
    border: 1px solid #EDD3FF;
    border-radius: 24px;
    padding: 1rem 0.7rem;
    color: gray;
    transition: 0.1s ease-out;
    height: 45px;
}

.material-textfield input:focus,
.material-textfield select:focus {
    border-color: #6A0DAD;
    padding-left: 25px;
}

.material-textfield input:focus+label,
.material-textfield select:focus+label {
    color: #6A0DAD;
    top: 0;
    transform: translateY(-50%) scale(0.9) !important;
}

.material-textfield input:not(:placeholder-shown)+label,
.material-textfield select:not(:placeholder-shown)+label {
    top: 0;
    transform: translateY(-50%) scale(0.9) !important;
    padding-left: 20px;
}

.material-textfield input:not(:focus)::placeholder,
.material-textfield select:not(:focus)::placeholder {
    opacity: 0;
}