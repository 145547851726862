.product-img {
    text-align: center;
}

.product-item {
    /* border: 1px solid #3cac47;  */
    padding: 10px;
    border-radius: 5px;
    max-width: 190px;
    margin-bottom: 20px;
    margin-right: 12px;
    -webkit-box-shadow: 0 0 10px #ddd;
    box-shadow: 0 0 10px 0px #ddd;
    border: 0.5px solid rgb(232, 232, 232);
}

.product-item-mobile {
    min-width: 100%;
    cursor: pointer;
    background: rgb(255, 255, 255);
    border: 0.5px solid rgb(232, 232, 232);
    box-shadow: rgba(0, 0, 0, 0.04) 2px 2px 8px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.product-mobile-para {
    overflow: hidden;
    color: rgb(31, 31, 31);
    font-weight: 600;
    font-size: 13px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 18px;
    width: 100%;
    height: 36px;
}

.product-item p {
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
}

.product-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

p.produc-price {
    padding: 0;
    text-decoration: line-through;
    color: rgb(153, 153, 153);
}

p.price {
    text-decoration: none;
    font-weight: bold;
    padding: 0;
}

.offer-price {
    color: white;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    background-color: rgb(49, 146, 170);
    padding: 5%;
    height: 1rem;
    font-weight: bold;
}

.p-price {
    -webkit-box-pack: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.p-btn {
    border-radius: 8px;
    box-shadow: 0 0 10px 0px #ddd;

}

.btn.btn-cart {
    width: 90px;
    background: #f7fff9;
    height: 100%;
}

.product-img img {
    max-width: 100%;
    border-radius: 5px;
    margin: auto;
    height: 120px;
}

.slick-slider .slick-prev:before,
.slick-slider .slick-next:before {
    color: #6c757d;
    font-size: 30px;
    margin-left: -5px;
}

.products-container {
    margin-bottom: 30px;
}

.view-all {
    text-decoration: none;
    font-size: 14px;
    color: #6c757d;
    font-weight: 500;
    margin-right: 30px;
}

.view-all:hover {
    text-decoration: underline;
}


h2 {
    margin-bottom: 10px;
}

img.cart-img {
    max-width: 30px;
}

.btn-cart-added {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    background: #3cac47 !important;
    color: #fff;
}

.qty {
    flex: 1;
}

button.btn-minus {
    flex: 1;
    padding: 0px;
    background: none;
    border: none;
    color: #fff;
}

.btn-cart-added:hover,
.btn-cart-added:active {
    color: #fff !important;
}

.product-detail-info {
    padding: 60px;
    border-left: 1px solid #ddd;
}

.product-detail {
    border: 1px solid #ddd;
}

.product-detail-img img {
    width: 400px;
}

.product-detail-img {
    text-align: center;
}

.product-detail .p-title {
    font-size: 24px;
    font-weight: bold;
}

.product-detail .produc-price {
    font-weight: bold;
}

.product-detail-actions .product-actions {
    float: right;
}

.product-description {
    padding: 60px;
}

.product-description .desc-title {
    font-size: 14px;
    font-weight: bold;
}

.slick-next {
    right: -18px;
}

@media screen and (max-width: 767.98px) {
    .product-img img {
        max-width: 100%;
    }

    .product-item {
        max-width: calc(100% - 10px);
    }

    .slick-slider .slick-next:before {
        margin-left: 0px;
    }

    .slick-next {
        right: 10px;
        z-index: 9;
    }

    .slick-prev {
        left: -10px;
        z-index: 9;
    }
}