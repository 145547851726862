.desktop-container {
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
}

.content {
    flex: 1;
    overflow-y: auto;
    /* padding: 10px; */
}

.header-content {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
}

.top-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    /* position: fixed; */
    background: white;
    /* top: 0; */
    /* z-index: 5; */
    /* width: 100%; */
    box-shadow: 0 2px 4px 0 rgba(185, 185, 185, 0.5);
    height: 75px;
}

.logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 23px;
    /* width: 343px; */
}

.mobile-search-icon {
    display: none;
}

.search {
    display: flex;
    display: flex;
    align-items: center;
    border: 1px solid #cccccc;
    border-radius: 40px;
    background-color: #f4d8ff;
    padding: 10px 28px 8px 8px;
    width: 100%;
    max-width: 529px;
    margin: 0 auto;
    height: 45px;
    transform: translate(-7%, 2%);
}

.search input,
.searches input,
.application-search input {
    border: none;
    background: transparent;
    outline: none;
    flex-grow: 1;
    font-size: 16px;
    padding: 8px;
}

.search input::placeholder,
.searches input::placeholder {
    color: #888;
}


.search-mobile {
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #ddd;
    display: flex;
    justify-content: center;
    flex: 1;
    padding: 10px
}

.search-mobile>input {
    border: none;
    margin-left: 10px;
    flex: 1;
    padding: 0px 10px 0px 20px;
    background-color: #ddd;
}

.search-mobile>input:focus {
    outline: none;
}

.search>input,
.application-search>input {
    border: none;
    margin-left: 10px;
}

.search>input:focus {
    outline: none;
}

.logo-image {
    width: 195px;
    height: auto;
}

.content {
    overflow-y: scroll;
}

.login-text {
    font-size: 16px;
    font-weight: bold;
    color: black;
    cursor: pointer;
}

.login-text-type {
    font-size: 24px;
    font-weight: 600;
    color: #212529;
}

.login-text:hover {
    text-decoration: underline;
}

.options {
    text-align: right;
    margin-right: 20px;
    /* width: 375px; */
}

.options-mobile {
    display: none;
    flex-grow: 1;
    text-align: end;
    position: absolute;
    bottom: 0;
}

.options-container {
    display: flex;
    justify-content: end;
    gap: 19px;
    padding: 10px;
}

.options-container-mobile {
    display: flex;
    justify-content: end;
    gap: 10px;
    padding: 10px;
    position: fixed;
    bottom: 0;
    background: white;
    width: 100%;
    z-index: 99;
}

.options-container-mobile {
    justify-content: space-between;
    border-top: 1px solid #dbdbdb;
    box-shadow: 0px 0px 5px 0px #00000040;
}

.option-icon {
    width: 30px;
    height: 30px;
}

.option-icon-mbl {
    width: 24px;
    height: 24px;
}

.options-container-mobile a.active p {
    font-weight: 500;
}

.options-container-mobile p {
    margin: 0px;
    font-size: 10px;
    line-height: 15px;
}

.search input[type="text"],
.application-search input[type="text"] {
    width: 100%;
}

.top-mobile-main {
    display: none;
}

.search-bar-filter {
    width: 100%;
    display: flex;
}

.social-icons {
    width: 20px;
    height: 20px;
}

.social-align {
    display: flex;
    gap: 15px;
    justify-content: center;
}

.follow-us {
    position: absolute;
    bottom: 16px;
    width: 100%;
}

.dropdown-margin .dropdown-item {
    margin-top: 10px;
}

@media screen and (max-width: 767.98px) {
    .top-mobile-main {
        display: block !important;
    }

    .top-mobile-main {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        z-index: 5;
        width: 100%;
        background: white;
        box-shadow: 0 2px 4px 0 rgba(185, 185, 185, 0.5);
        /* overflow-x: hidden; */
    }

    .search {
        display: none;
    }

    .top-main {
        display: none;
    }

    .desktop-container {
        display: none;
    }

    .options-mobile {
        display: block;
    }

    .hidden {
        display: none !important;
    }

    .visible {
        display: block;
    }

    .options {
        width: 0px;
        margin-right: 16px;
    }

    .logo {
        width: 100%;
        padding: 7px 28px 7px 3px;
    }

    .sticky-header {
        flex-direction: column;
        text-align: center;
        padding: 15px;
    }
}

@media screen and (max-width: 991.98px) {
    .search {
        transform: none;
    }

    .search-screen-container {
        display: block;
    }

    .search-bar-filter {
        padding: 10px;
        gap: 5px;
        margin-top: 10px;
        position: relative;
        align-items: center;
    }

    .mobile-search-icon {
        display: block;
        border-radius: 50%;
        /* background-color: #ccc; */
    }

    .logo-image {
        margin-left: 0px;
        margin-right: 21px;
        height: auto;
        width: 162px;
    }

    .scrollable-content {
        flex: 1;
        overflow-y: auto;
    }

    .logo {
        width: auto;
    }

    .options {
        width: auto;
        margin-right: 0px;
    }

    .top-mobile-main {
        display: none;
    }

}

/* @media (min-width: 993px) and (max-width: 1200px){
    .search{
        max-width: 426px;
    }   
} */

.mobile-icons-tab a,
.mobile-icons-tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}