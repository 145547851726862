.category-item img {
    max-width: 100px;
    border-radius: 10px;
    max-height: 240px;
}
.category-item p {
    text-align: center;
    margin: 10px 0px;
    font-size: 16px;
    color: #000;
}
.category-item a {
    text-decoration: none;
}
.categories-container {
    margin-bottom: 30px;
}
.category-item {
    text-align: center;
    margin:12px;
}
.category-item {
    background: #0d6efd40;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
}