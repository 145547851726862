.order {
    margin-top: 2rem;
}

.image {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 1px solid black;
}

.status_badge {
    font-size: 0.8rem;
    color: white;
    font-weight: 600;
}

.status_badge>div {
    background-color: rgb(153,153,153);
    border-radius: 44px;
    padding: 2px 8px;
    font-size: 10px;
    font-weight: 700;
    line-height: 13px;
    text-align: center;
    text-transform: lowercase;
}

.view_details>a {
    text-decoration: none;
    color: green;
    border: 0.5px solid black;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.no-orders {
    font-weight: bold;
    font-size: 2rem;
    padding-top: 5rem;
    text-align: center;
}

.order-overflow {
    border-left: 1px solid #eee;
    overflow-y: scroll;
    height: 80vh;
}

.view-button{
    padding: 8px;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    
}


@media screen and (max-width: 767.98px) {
    .order_id{
        font-size: 14px;
        font-weight: 700;
        line-height: 18px
    }
    .gap-4 {
        gap: 1rem!important;
    }
}