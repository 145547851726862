@import url('https://fonts.googleapis.com/css2?family=Akaya+Kanadaka&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:wght@300;400;500;600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,500;1,700;1,900&display=swap');

.upgrade-header {
    text-align: center;
    /* background: linear-gradient(to bottom, #5e32a8 80%, #432479 80%); */
    color: #fff;
    padding: 20px 0px 0px 0px;
    border-radius: 0 0 30px 30px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    /* gap: 30px; */
    background-color: #5e32a8;
    position: relative;
    overflow: hidden;
    /* background: url({require("../images/proicon.png")}); */
}
.upgrade-header::after{
    content: '';
    display: block;
    height: 50px;
    width: 100%;
    bottom: 0;
    background-color: #432479;
    position: absolute;
    z-index: 0;
}
.pro_premium_member{
    position: relative;
    z-index: 1;
    padding-bottom: 10px !important;
    font-size: 12px;

}

.upgrade-header p {
    margin-bottom: 5px;
}

.upgrade-auto {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #0000004a;
}


.pro_upgrade_title{
    text-align: center;
    margin-bottom: 22px;
    margin-top: 20px;
}
.pro_upgrade_title img{
    width: 90px;
    margin-right: 6px;
}
.pro_upgrade_title .pro_member_text{
    width: 380px;


}
.pro-upgrade-auto .pro_exclusive{
    font-size: 30px;
    font-weight: 500;
    font-family: "Akaya Kanadaka", system-ui;
    padding-bottom: 34px; 
}
.pro-upgrade-auto .pro_exclusive::after{
        content: "";
        display: block;
        height: 2.2px; 
        background: linear-gradient(to left, #FEF478, transparent 50%), 
                    linear-gradient(to right, #FEF478, transparent 50%);
        background-repeat: no-repeat;
        background-position: left, right;
        background-size: 50% 100%;
        margin-top: 45px; 
        margin-bottom: 30px;
}
.upgrade-btn {
    background-color: #ffce00;
    color: #5e32a8;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1.2em;
    cursor: pointer;
    margin-top: 10px;
}

.upgrade-btn:hover {
    background-color: #ffd93b;
}
.action-buttons button{
    opacity: 1 !important;
}

/* .upgrade-h2 {
    display: inline;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    &::after,
    &::before {
        content: '';
        height: 2.2px;
        flex: 1;
        width: 30%;
        background: linear-gradient(to left, #6A0DAD , transparent 30%), 
                   linear-gradient(to right, #6A0DAD, transparent 30%);
    }
} */


.upgrade-h2 {
    position: relative;
    text-align: center;
    font-size: 24px; /* Adjust font size */
    color: #333; /* Adjust text color */
    font-weight: 600;
    margin: 40px 0;
  }
  
  .upgrade-h2::before,
  .upgrade-h2::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 30%; /* Adjust width of gradient lines */
    height: 2px; /* Adjust thickness */
    background: linear-gradient(to left, transparent, #6A0DAD);
    transform: translateY(-50%);
  }
  
  .upgrade-h2::before {
    left: 0; /* Position gradient line on the left */
    background: linear-gradient(to right, transparent, #6A0DAD);
  }
  
  .upgrade-h2::after {
    right: 0; /* Position gradient line on the right */
    background: linear-gradient(to left, transparent, #6A0DAD);
  }
  .action-buttons button{
    border-radius: 30px;
    background-color: #28a745 !important;
    border: none;
    margin-top: 15px;
  }
  

.pro-upgrade-auto {
    margin: auto;
    max-width:768px;
    border: 1px solid #0000004a;
}

.pro-upgrade-header {
    text-align: center;
    margin-bottom: 20px;
}

.pro-upgrade-header h3 {
    margin: 10px 0;
    font-size: 1.5rem;
}

.pro-hide-mobile {
    display: none;
}

.pro-hide-for-desktop {
    display: none;
}

.pro-container {
    margin: 20px 0;
    text-align: center;
}

.pro-upgrade-h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.pro-feature-list {
    list-style: none;
    padding: 0;
    text-align: left;
    display: inline-block;
    margin-top: 15px;
}

.pro-feature-list li {
    font-size: 1rem;
    margin: 10px 0;
    display: flex;
    align-items: center;
}

.pro-cta-section {
    text-align: center;
}

.pro-cta-section div {
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.pro-cta-section div:hover {
    background-color: #e0e0e0;
}

.user-plan-info {
    margin: 20px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.plan-title {
    text-align: center;
    color: #4a90e2;
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.plan-details {
    display: flex;
    flex-direction: column;
    gap:5px;
}

.plan-row {
    display: flex;
    justify-content: space-between;
    line-height: 1.5;
}
.status-row .value.active {
    color: #28a745; /* Green for active status */
    font-weight: bold;
}

.status-row .value.inactive {
    color: #dc3545; /* Red for inactive status */
    font-weight: bold;
}

section.cta-section.no-bg-padding div {
    background: none;
    padding: 5px 0;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 767.98px) {
    .pro-upgrade-header h3 {
        font-size: 1.2rem;
    }

    .pro-upgrade-h2 {
        font-size: 1.5rem;
    }

    .pro-feature-list li {
        font-size: 0.9rem;
    }

    .pro-cta-section div {
        padding: 10px;
    }

    .pro-plan-card {
        padding: 15px;
    }

    .pro-btn {
        padding: 8px 16px;
        font-size: 0.9rem;
    }

    .pro-hide-mobile {
        display: block;
    }

    .pro-hide-for-desktop {
        display: none;
    }
    .upgrade-h2::before, .upgrade-h2::after{
        display: none !important;
    }
    .cta-section h2{
        font-size: 16px !important;
    }
    .pro_upgrade_title{
        margin-bottom: 2px;
        margin-top: 1px;
    }
    .pro_upgrade_title .pro_member_text{
        width: 300px;
    }
    .pro-upgrade-auto .pro_exclusive{
        line-height: 38px;
        font-size: 23px;
    }
    .pro_upgrade_title img{
        width: 72px;
        margin-right: 0px;
    }
    .cta-section{
        padding: 17px 22px;
    }
    .pro-upgrade-auto .pro_exclusive::after{
        margin-top: 4px;
        margin-bottom: 14px;
    }
    .pro_premium_member{
        padding-bottom: 5px !important;
        font-size: 12px;
        display: block !important;
    }
    .upgrade-header::after{
        height: 75px;
    }
}

@media (max-width: 480px) {
    .pro-upgrade-auto {
        padding: 10px;
    }

    .pro-upgrade-header h3 {
        font-size: 1rem;
    }

    .pro-feature-list li {
        font-size: 0.8rem;
    }

    .pro-plan-details p {
        font-size: 0.9rem;
    }

    .pro-btn {
        padding: 6px 12px;
        font-size: 0.8rem;
    }

    .pro-cta-section h2 {
        font-size: 1.3rem;
    }
    .upgrade-h2::before, .upgrade-h2::after{
        width: 20%;
    }
}
@media screen and (max-width: 991.98px) {
    .upgrade-h2::before, .upgrade-h2::after{
        width: 23%;
    }
    .pro_premium_member{
        padding-bottom: 2px !important;
        font-size: 12px;
        display: block !important;
    }
    
}
