.back-to-orders{
    border: 1.5px solid #f9f9f9;
    border-radius: 0.2rem;
    padding: 0.5rem;
    color: black;
    position: absolute;
    margin: 0;
    box-shadow: 2px 2px 2px grey;
}

.order-content{
    margin: 5rem 4rem;
}

.pay-mode{
    background-color: #f8f8f8;
    border-radius: 1rem;
    margin: 1.5rem 4rem;
}

.order-summary{
    border: 1px solid #f8f8f8;
    border-radius: 1rem;
    margin: 1.5rem 4rem;
}

.order-bill-details{
    margin: 1.5rem 4rem;
}

.download-summary{
    text-decoration: none;
    color: green;
}

.order-item-image{
    width: 4rem;
    height: 4rem;
}

.order-saving{
    color: green;
    border: 1px solid green;
    background-color: #ecffec;
    border-radius: 0.2rem;
}

.customer-support{
    background-color: green;
    color: white;
}

